import actionType from '../constants/notifications'
// import { store } from '../../index'
import store from '../../store'
import $axios from 'src/utils/axios';
import IP from "src/StoreGlobal/IP";

export const requestMaterialNotifications = (company_id, project_id) => {

  $axios.post('material/notifications', {
    company_id: company_id, 
    project_id: project_id
  }).then((response) => {
    store.dispatch({type: 'set', materialNotifications: response.data.response})
  })
}

export const requestSowNotifications = (company_id, project_id) => {
  console.log('REQUESTING SOW NOTIF')

  $axios.post('sow/notifications',{ company_id: company_id, project_id: project_id }).then((response) => {
    console.log(response.data.response, 'response')
    store.dispatch({type: 'set', sowNotifications: response.data.response})
  })
}



export const action_newNotification = (notifications) => {
  console.log(notifications, 'HERE IS ME')

  return {
    type : actionType.NEW_NOTIFICATIONS,
    notifications
  }
}

export const workItemStatusNotification = (project_id) => {
  const endpoints = [
    `project/work_item/overdue?project_id=${project_id}`,
    `project/work_item/to_start?project_id=${project_id}`,
    `project/work_item/to_end?project_id=${project_id}`,
  ];

  return Promise.all(endpoints.map((endpoint) => $axios.get(endpoint)))
    .then((responses) => {
      const data = responses.flatMap((response) => response.data.data);
      return data;
    })
    .catch((error) => {
      throw error;
    });
};
