import React, { useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCol, CDataTable, CInput, CPagination, CRow, CLabel, CSpinner, CTabs, CNav, CNavItem, CNavLink, CBadge } from '@coreui/react';
import { useSelector } from 'react-redux';
import ReorderPoint from './ReorderPoint';
import { GetMaterialUtilizationProjectData, GetMaterialUtilizationProjectDataNew, GetProjectsMaterialUtilization, GetProjectStockBalance, GetTransferMaterialList } from 'src/request/OnsiteRecords';
import { DownloadReport } from 'src/request/Projects';
import Select, { components } from 'react-select';
import TransferForm from './TransferForm';
import TransferMaterialHistory from './TransferMaterialHistory';
import ReceiveTransferMaterial from './ReceiveTransferMaterial';
import TransferMaterialDebt from './TransferMaterialDebt';

const sampleDebtData = [
  { transaction_id: 12345, date: '09-16-2024', return_to: 'FGD Interior Fit Out FGD Interior Fit Out', submitted_by: 'User 1', status: 'For Return', action: 'Return' },
  { transaction_id: 12346, date: '09-16-2024', return_to: 'FGD Interior Fit Out FGD Interior Fit Out', submitted_by: 'User 1', status: 'For Return', action: 'Return' },
];
const StockBalance = (props) => {
  const company_id = parseInt(localStorage.getItem('company_id'));
  const Swal = require('sweetalert2');
  let permission = useSelector((state) => state.permission);
  const fields = [
    {
      key: 'material_description',
      label: 'Material',
      _style: { border: 'none', minWidth: '50%', width: '50%' },
    },
    {
      key: 'received_qty_over_stock_balance',
      label: 'Stock Balance / Received Quantity',
      _style: { border: 'none', minWidth: '60%', width: '50%' },
    },
    {
      key: 'sow_name',
      label: 'SOW',
      _style: { display: 'none' },
    },
  ];

  const [isReorderPointOpen, setIsReorderPointOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(projectOptions[0] ? { value: projectOptions[0]?.value, label: projectOptions[0]?.label } : null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [receiveMaterialCount, setReceiveMaterialCount] = useState(0);
  const [materialDebtCount, setMaterialDebtCount] = useState(0);

  const handleReorderPoint = () => {
    setIsReorderPointOpen(true);
    setIsModalOpen(true);
  };

  const handleOnPageChange = (e) => {
    setCurrentPage(e);
  };

  useEffect(() => {
    const fetchProjectOptions = () => {
      setIsLoading(true);
      GetProjectStockBalance()
        .then((response) => {
          const projectOptionsData = response.data.response.data;
          console.log('projectOptionsData', projectOptionsData);
          setProjectOptions(projectOptionsData);
          setSelectedProject(projectOptionsData[0]);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchProjectOptions();
  }, []);

  useEffect(() => {
    if (selectedProject || props.from_project_module === true) {
      fetchData(props.from_project_module === true ? props.projectID : selectedProject?.value, currentPage, 10, searchQuery);
      fetchReceiveMaterialCount(props.from_project_module === true ? props.projectID : selectedProject?.value);
      fetchMaterialDebtCount(props.from_project_module === true ? props.projectID : selectedProject?.value);
    }
  }, [currentPage, searchQuery, selectedProject, activeTab, props.from_project_module]);

  const fetchData = (project_id, page, pageSize, search = null) => {
    GetMaterialUtilizationProjectDataNew(project_id, page, pageSize, search).then((response) => {
      setData(response.data.data);
      setTotalPages(response.data.total_pages);
    });
  };

  const fetchReceiveMaterialCount = (project_id) => {
    GetTransferMaterialList(1, 1, null, project_id, 1, null).then((response) => {
      setReceiveMaterialCount(response.data.total_items);
    });
  };

  const fetchMaterialDebtCount = (project_id) => {
    GetTransferMaterialList(1, 1, null, project_id, null, 1).then((response) => {
      setMaterialDebtCount(response.data.total_items);
    });
  };

  const handleOnSuccess = () => {
    fetchData(selectedProject?.value, currentPage, 10, searchQuery);
    fetchReceiveMaterialCount(selectedProject?.value);
    fetchMaterialDebtCount(selectedProject?.value);
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearchChange = debounce((e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  }, 300);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await DownloadReport(props.project_id, 6);
    setIsLoading(false);
  };

  const handleSelectChange = (name) => (selectedOption) => {
    // setForm({ ...form, [name]: selectedOption.value });

    if (name === 'project_id' && selectedOption.value) {
      setSelectedProject(selectedOption);
    }
  };

  return (
    <CCard className="shadow border-0 even" style={{ minHeight: '800px' }}>
      <CCardBody>
        <CLabel className="h4">{props.from_project_module === true ? 'Material Utilization' : 'Stock Balance'}</CLabel>
        <CCol style={{ padding: '0px' }}>
          <CLabel>Overview of on-site inventory of materials</CLabel>
        </CCol>
        {props.from_project_module === undefined && (
          <CRow xs="12" md="6" lg="6" xl="6">
            <CCol xs="12" md="12" lg="6" xl="6" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '12px', marginLeft: '10px' }}>
              <CLabel style={{ marginRight: '12px', width: '110px', marginTop: '10px' }}>Search Project</CLabel>
              <Select
                name="project_id"
                placeholder="Select Project"
                options={projectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '98%',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                value={selectedProject}
                menuPortalTarget={document.body}
                onChange={handleSelectChange('project_id')}
              />
            </CCol>
          </CRow>
        )}
        <div className="d-flex justify-content-end">
          {/* <CButton color="aimhi-textlight-neutral" variant="outline" className="mb-3" style={{ alignSelf: 'flex-start', marginRight: '12px' }} onClick={handleDownloadReport}>
            {isLoading ? (
              <>
                <CSpinner color="#23303D" size="sm" />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </>
            ) : (
              'Download'
            )}
          </CButton> */}
          {/* <CButton color="aimhi-textlight-neutral" className="mb-3" style={{ alignSelf: 'flex-start' }} onClick={handleReorderPoint}>
            Set Reorder Point
          </CButton> */}
        </div>
        {/* <ReorderPoint isOpen={isReorderPointOpen} onClose={() => setIsReorderPointOpen(!isReorderPointOpen)} projectId={props.project_id} /> */}
        {isModalOpen && <TransferForm isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} projectId={props.from_project_module === true ? props.projectID : selectedProject?.value} onSuccess={handleOnSuccess} />}
        <CCol>
          {props.from_project_module === undefined && (
            <CLabel className="h5" style={{ paddingTop: '24px', paddingBottom: '12px' }}>
              {selectedProject ? `Project: ${selectedProject.label}` : `Project: ${projectOptions[0]?.label}`}
            </CLabel>
          )}
          <CTabs className="pt-3">
            <CNav variant="tabs" style={{ borderWidth: '5px' }}>
              <CNavItem>
                <CNavLink className={activeTab == 1 ? 'nav-design-active' : 'nav-design'} onClick={() => setActiveTab(1)}>
                  Stock Balance
                </CNavLink>
              </CNavItem>
              <CNavItem style={{ position: 'relative' }}>
                <CNavLink className={activeTab == 2 ? 'nav-design-active' : 'nav-design'} onClick={() => setActiveTab(2)}>
                  Material Debts
                  {materialDebtCount > 0 && (
                    <CBadge
                      color="aimhi-danger"
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-8px',
                        padding: '0.4em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        zIndex: '10',
                      }}
                    >
                      {materialDebtCount}
                    </CBadge>
                  )}
                </CNavLink>
              </CNavItem>
              <CNavItem style={{ position: 'relative' }}>
                <CNavLink className={activeTab == 3 ? 'nav-design-active' : 'nav-design'} onClick={() => setActiveTab(3)}>
                  Receive Materials
                  {receiveMaterialCount > 0 && (
                    <CBadge
                      color="aimhi-danger"
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-8px',
                        padding: '0.4em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                      }}
                    >
                      {receiveMaterialCount}
                    </CBadge>
                  )}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink className={activeTab == 4 ? 'nav-design-active' : 'nav-design'} onClick={() => setActiveTab(4)}>
                  History
                </CNavLink>
              </CNavItem>
            </CNav>
          </CTabs>
          <div style={{ marginTop: '24px' }}></div>
          {activeTab === 1 && (
            <>
              <CDataTable
                items={data}
                fields={fields}
                itemsPerPage={10}
                responsive
                scopedSlots={{
                  material_description: (item) => <td style={{ border: 'none' }}>{`${item.material_description} (${item.sow_name})`}</td>,
                  received_qty_over_stock_balance: (item) => {
                    let progress = (parseInt(item.stock_balance) / parseInt(item.total_order)) * 100;

                    let color = '#3a7d21';
                    if (progress >= 50 && progress === 100) {
                      color = '#3a7d21';
                    } else if (progress < 50) {
                      color = '#f2e442';
                    }
                    return (
                      <td style={{ border: 'none' }}>
                        <div className="progress" style={{ position: 'relative', borderRadius: '10px', height: '20px', background: progress === 0 ? '#B42A19' : '' }}>
                          <div
                            className="progress-bar"
                            style={{
                              width: progress + '%',
                              background: color,
                              height: '20px',
                              color: color === '#3a7d21' ? 'white' : 'black',
                            }}
                          >
                            {item.stock_balance}
                          </div>
                          <span
                            style={{
                              position: 'absolute',
                              top: '10px',
                              left: '85%',
                              color: progress === 0 || progress >= 92 ? 'white' : '#23303d',
                            }}
                          >
                            {item.stock_balance}/{item.total_order}
                          </span>
                        </div>
                      </td>
                    );
                  },
                  sow_name: (item) => {
                    return <td style={{ display: 'none' }}>{item.sow_name}</td>;
                  },
                }}
                overTableSlot={
                  //   <CRow xs="12" md="6" lg="6" xl="6">
                  //     <CCol xs="6" md="6" lg="3" xl="3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '12px', marginLeft: '10px' }}>
                  //       <CLabel style={{ marginRight: '12px' }}>Filter</CLabel>
                  //       <CInput type="text" placeholder="Search material name" onChange={handleSearchChange} />
                  //       <CButton color="aimhi-textlight-neutral" style={{ alignSelf: 'flex-start' }} onClick={handleReorderPoint}>
                  //         Transfer
                  //       </CButton>
                  //     </CCol>
                  //   </CRow>
                  <CRow xs="12" md="6" lg="6" xl="6">
                    <CCol xs="12" md="12" lg="12" xl="12" style={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
                      <CLabel style={{ marginRight: '12px', marginLeft: '12px' }}>Filter</CLabel>
                      <CInput type="text" placeholder="Search material name" onChange={handleSearchChange} style={{ flex: '1' }} />
                      <div style={{ flexGrow: 1 }}></div> {/* Spacer to push the button to the right */}
                      {permission['create'][company_id === 1 ? 8 : 7] === 1 && (
                        <CButton color="aimhi-textlight-neutral" onClick={handleReorderPoint}>
                          Transfer
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                }
              />
              {totalPages > 1 && (
                <CPagination
                  activePage={currentPage}
                  pages={totalPages}
                  onActivePageChange={(i) => {
                    if (i === 0) {
                      i = 1;
                    }
                    handleOnPageChange(i);
                  }}
                ></CPagination>
              )}
            </>
          )}
          {activeTab === 2 && <TransferMaterialDebt project_id={props.from_project_module === true ? props.projectID : selectedProject?.value} onSuccess={handleOnSuccess} />}
          {activeTab === 3 && <ReceiveTransferMaterial project_id={props.from_project_module === true ? props.projectID : selectedProject?.value} onSuccess={handleOnSuccess} />}
          {activeTab === 4 && <TransferMaterialHistory project_id={props.from_project_module === true ? props.projectID : selectedProject?.value} project_name={selectedProject?.label} />}
        </CCol>
      </CCardBody>
    </CCard>
  );
};
export default StockBalance;
