import React, { useState, useEffect } from "react";
import {
  CButton,
  CCol,
  CRow,
  CInput,
  CLabel,
  CSelect,
  CFormGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
} from "@coreui/react";

import "react-circular-progressbar/dist/styles.css";
import "./ProjectDetails.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  FaArchive,
  FaPen,
  FaTimes,
  FaUndoAlt,
} from "react-icons/fa";
import {
  getReport,
  saveDilyReport,
  getDetails,
  editReport,
  changeStatus,
} from "src/request/Reports";
let data = [
  {
    report_id: 1,
    date: "01-02-2022",
    submitted: "jose Rizal",
    last_modified: "01-02-2022,4:38pm",
  },
  {
    report_id: 2,
    date: "01-02-2022",
    submitted: "jose Rizal",
    last_modified: "01-02-2022,4:38pm",
  },
  {
    report_id: 3,
    date: "01-02-2022",
    submitted: "jose Rizal",
    last_modified: "01-02-2022,4:38pm",
  },
];

const SiteVisit = (props) => {
  let user_id = parseInt(localStorage.getItem("user_id"));
  const [siteVist, setSiteVisit] = useState([]);
  const [dateReq, setDateReq] = useState(false);
  const [purposeReq, setPurposeReq] = useState(false);
  const [majorReq, setMajorReq] = useState(false);
  const [cond, setCond] = useState("Add");
  const [reportLogId, setReportLogId] = useState(-1);
  const [status, setStatus] = useState(0);
  const [schedule, setSchedule] = useState([
    {
      report_id: "",
      date: "",
      purpose: "",
      event: "",
      report_log_id: reportLogId,
    },
  ]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getSiteVisit(0);
  }, []);

  const getSiteVisit = (status) => {
    getReport(props.id, "site_visit", status).then((res) => {
      if (res.data.status == 1) {
        setSiteVisit(res.data.response);
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: "#23303D",
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const SaveSchedule = () => {
    saveDilyReport(schedule, "site_visit", props.id, user_id).then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setModal(!modal);
        getSiteVisit(status);
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: "#23303D",
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const getReportById = (report_log_id) => {
    setReportLogId(report_log_id);
    getDetails("site_visit", report_log_id).then((res) => {
      if (res.data.status == 1) {
        setMajorReq(true);
        setPurposeReq(true);
        setDateReq(true);
        setSchedule(res.data.response);

        setModal(!modal);
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: "#23303D",
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const updateSchedule = () => {
    editReport(schedule, "site_visit").then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        getSiteVisit(status);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: "#23303D",
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const changeStatusReport = (report_log_id, method) => {
    Swal.fire({
      icon: "question",
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed,you will archived all your <br> data.Are you sure you want to <br> achive this report?</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#39f",
      cancelButtonColor: "#B42A19",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(report_log_id, method).then((res) => {
          if (res.data.status == 1) {
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "Your work has been archived",
              showConfirmButton: false,
              timer: 1500,
            });
            getSiteVisit(status);
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: "#23303D",
              // footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        });
      }
    });
  };

  const add = () => {
    let arr = [...schedule];
    arr.push({
      report_id: -1,
      date: "",
      purpose: "",
      event: "",
      report_log_id: reportLogId,
    });
    setMajorReq(false);
    setPurposeReq(false);
    setDateReq(false);
    setSchedule(arr);
  };

  const remove = (Index) => {
    let arr = [...schedule];
    arr.splice(Index, 1);
    setMajorReq(true);
    setPurposeReq(true);
    setDateReq(true);
    setSchedule(arr);
  };

  const dateChange = (e, Index) => {
    let arr = [...schedule];

    arr[Index].date = e.target.value;
    setDateReq(true);
    setSchedule(arr);
  };

  const purposeChange = (e, Index) => {
    let arr = [...schedule];

    arr[Index].purpose = e.target.value;
    setPurposeReq(true);
    setSchedule(arr);
  };
  const eventChange = (e, Index) => {
    let arr = [...schedule];
    setMajorReq(true);
    arr[Index].event = e.target.value;

    setSchedule(arr);
  };

  const AddNew = () => {
    setModal(!modal);
    setCond("Add");
    setSchedule([
      {
        report_id: "",
        date: "",
        purpose: "",
        event: "",
        report_log_id: reportLogId,
      },
    ]);
  };

  const dataTable = siteVist.map((data, Index) => {
    return (
      <tr key={Index}>
        <td className="pl-0" style={{ paddingTop: '6px'}}>
          <CButton
            color="link"
            style={{ color: "#23303d" }}
            onClick={() => getReportById(data.report_log_id, setCond("View"))}
          >
            {data.date}
          </CButton>
        </td>
        <td>{data.submitted}</td>
        <td>{data.last_modified}</td>
        <td>
          {status == 0 && (
            <FaPen
              onClick={() => getReportById(data.report_log_id, setCond("Edit"))}
            />
          )}

          {status == 0 ? (
            <FaArchive
              style={{ marginLeft: "5px" }}
              onClick={() => changeStatusReport(data.report_log_id, "DELETE")}
            />
          ) : (
            <FaUndoAlt
              style={{ marginLeft: "5px" }}
              onClick={() => changeStatusReport(data.report_log_id, "PUT")}
            />
          )}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  });

  const AddFormTable = schedule.map((data, Index) => {
    return (
      <tr key={Index}>
        <td>
          <CInput
            type="date"
            value={data.date}
            onChange={(e) => dateChange(e, Index)}
            disabled={cond == "View"}
          />
        </td>
        <td>
          <CInput
            type="text"
            value={data.purpose}
            onChange={(e) => purposeChange(e, Index)}
            disabled={cond == "View"}
          />
        </td>
        <td>
          <CInput
            type="text"
            value={data.event}
            onChange={(e) => eventChange(e, Index)}
            disabled={cond == "View"}
          />
        </td>
        {cond != "View" && (
          <td>
            <FaTimes onClick={() => remove(Index)} />
          </td>
        )}
      </tr>
    );
  });

  return (
    <>
      <CRow>
        <CCol
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="9"
          style={{ marginTop: "20px" }}
        >
          <CCol xs="12" sm="12" md="12" lg="12" xl="12">
            <CFormGroup row style={{ marginLeft: "2px" }}>
              <CLabel>Filter View</CLabel>

              <CCol
                xs="12"
                sm="12"
                md="3"
                lg="3"
                className="margin-mobile float-left"
              >
                <CSelect
                  name="select"
                  id="select"
                  size="sm"
                  onChange={(e) =>
                    getSiteVisit(e.target.value, setStatus(e.target.value))
                  }
                  value={status}
                >
                  <option disabled value="">
                    Status
                  </option>

                  <option value={0}>Active</option>
                  <option value={1}>Archived</option>
                </CSelect>
              </CCol>
            </CFormGroup>
          </CCol>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Date</th>
                <th>Submitted by</th>
                <th>Last Modified</th>
                <th>Action</th>
                <th></th>
                <th></th>
                <th>
                  {status == 0 && (
                    <CButton
                      size="sm"
                      color="aimhi-primary"
                      block
                      onClick={() => AddNew()}
                    >
                      Add New
                    </CButton>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataTable}
              <tr>
                {siteVist.length == 0 && (
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No Data Available
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CModal
        show={modal}
        onClose={() => setModal(!modal)}
        style={{ borderRadius: "5px" }}
        size="lg"
      >
        <CModalHeader closeButton>
          <CModalTitle>Site Visit Schedule</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Primary Purpose</th>
                  <th>Major Event</th>
                  {cond != "View" && <th>Action</th>}
                </tr>
              </thead>
              <tbody>{AddFormTable}</tbody>
            </table>
            {cond != "View" && (
              <CButton
                onClick={() => add()}
                disabled={!dateReq || !purposeReq || !majorReq}
              >
                <u>Add More</u>
              </CButton>
            )}
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: "white" }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            {cond == "Edit" && (
              <CButton
                block
                color="aimhi-textlight-neutral"
                size="sm"
                variant="outline"
                disabled={!dateReq || !purposeReq || !majorReq}
                onClick={updateSchedule}
              >
                Update
              </CButton>
            )}
            {cond == "Add" && (
              <CButton
                block
                color="aimhi-textlight-neutral"
                size="sm"
                variant="outline"
                disabled={!dateReq || !purposeReq || !majorReq}
                onClick={SaveSchedule}
              >
                Submit
              </CButton>
            )}
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default SiteVisit;
