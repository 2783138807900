import React, { useState, useEffect, useRef } from 'react';
import { CButton, CCol, CRow, CInput, CTabs, CNav, CNavItem, CNavLink, CLabel, CSelect, CFormGroup, CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody, CTextarea, CPagination } from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import Loading from '../aimhi-widgets/Loading';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import ThingsToConsider from '../aimhi-widgets/ThingToConsider';
import PrintPurchaseRequest from './PrintPurchaseRequest';
import $axios from 'src/utils/axios';
import Select from 'react-select';
import IP from '../../StoreGlobal/IP';
import { requestMaterialNotifications } from 'src/redux/actions/notifications';
import { FaPencilAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import RecommenderPopUp from '../aimhi-widgets/RecommenderPopUp';
import MaterialUtilizationProject from '../resource-records/material/MaterialUtilizationProject';
import StockBalance from '../resource-records/material/StockBalance';

let res = {
  items: [
    {
      pr_id: 142,
      tx_id: 362,
      item: 'Moldflex#\u00bd  (Permits and Clearances)',
      unit: '',
      qty: 1.0,
      notes: '',
    },
  ],
  approver: 'Ash',
  approver_position: 'Super Admin',
  preparer: 'ash',
  preparer_position: 'Site Engineer',
  date_approved: '03/13/2023',
  date_requested: '03/13/2023',
  pr_num: '03132312483040',
  notes: '',
};

const Materials = (props) => {
  const Swal = require('sweetalert2');

  let user_id = parseInt(localStorage.getItem('user_id'));

  const company_id = parseInt(localStorage.getItem('company_id'));

  const accessToken = localStorage.getItem('token');

  const history = useHistory();

  const [active, setActive] = useState(1);
  const [clickPrintNotif, setClickPrintNotif] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalEmail, setmodalEmail] = useState(false);
  const [listOfMaterial, setlistOfMaterial] = useState([]);
  const [purchaseRequest, setpurchaseRequest] = useState({});
  const [edit, setEdit] = useState(false);
  const [dataRequest, setDataRequest] = useState([]);
  const [approver, setApprover] = useState('');
  const [position, setPosition] = useState('');
  const [dateApproved, setDateApproved] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [addMaterial, setAddMaterial] = useState([
    {
      Description: '',
      Unit: '',
      Qty: '',
    },
  ]);

  const redux_module = useSelector((state) => state.module);
  const [description, setdescription] = useState(false);
  const [unit, setunit] = useState(false);
  const [qty, setqty] = useState(false);

  const [print, setprint] = useState(false);
  const [projectId, setprojectId] = useState('');
  const [materialAll, setmaterialAll] = useState([]);
  const [email, setemail] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [postperPage, setpostperPage] = useState(10);
  const [paginateInitial, setPaginateInitial] = useState(0);
  const [paginateAction, setPaginateAction] = useState('');
  const [requestHistory, setRequestHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preparer, setPreparer] = useState('');
  const [preparerPosition, setPreparerPosition] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [prNumber, setPrNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const [createRequest, setCreateRequest] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [location, setLocation] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [companyProfilePicture, setcompanyProfilePicture] = useState('');
  const [department, setDepartment] = useState('');
  const [originalQuantities, setOriginalQuantities] = useState({});
  const searchLocation = useLocation();
  const queryParams = new URLSearchParams(searchLocation?.search || '');
  const searchQuery = queryParams.get('search') || null;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [issue, setissue] = useState([]);
  let permission = useSelector((state) => state.permission);

  useEffect(() => {
    let user_id = parseInt(localStorage.getItem('user_id'));

    if (typeof props.project_id == 'undefined') {
      history.push({
        pathname: '../projects/Projects',
      });
    } else {
      setprojectId(props.project_id);
      getproject(props.project_id);
      // gitmaterialdropdown();
      gitRequstHistory(props.project_id);
    }
    if(searchQuery){
      setSearchValue(searchQuery)
    }
    getCompanyData(company_id);
    GetUsersById(user_id);
  }, [props]);

  useEffect(
    () => {
      console.log(createRequest, 'createRequest');
      console.log(dataRequest, 'dataRequest');
    },
    [createRequest],
    [dataRequest],
  );

  useEffect(() => {
    setSearchValue('')
  }, [active])
  const handleRedirectOnLowStock = (materialName) => {
    setActive(1);
    setSearchValue(materialName);
  };

  if ('matchMedia' in window) {
    // Chrome, Firefox, and IE 10 support mediaMatch listeners
    window.matchMedia('print').addListener(function (media) {
      if (media.matches) {
        beforePrint();
      } else {
        // Fires immediately, so wait for the first mouse movement
        afterPrint();
      }
    });
  } else {
  }

  const beforePrint = () => {
    setClickPrintNotif(true);
  };

  const afterPrint = () => {
    setClickPrintNotif(false);
  };

  const indexOfLastPost = currentPage * postperPage;
  const indexOfFirstPost = indexOfLastPost - postperPage;
  const currentPost = [...listOfMaterial].slice(indexOfFirstPost, indexOfLastPost);
  // const currentPost = listOfMaterial
  let pageNumbers = [];
  let paginateNumber = [];

  const paginateArray = () => {
    // Calculate the starting index and ending index for the current page
    const startIndex = currentPage <= 0 ? 0 : (currentPage - 1) * 10;
    const endIndex = startIndex + 10;

    return listOfMaterial.length > 0 ? listOfMaterial.filter((a) => a.MaterialName.toLowerCase().includes(searchValue.toLowerCase())).slice(startIndex, endIndex) : [];
  };

  for (let i = 1; i <= Math.ceil(listOfMaterial.length / postperPage); i++) {
    // paginateNumber.push(i);
    if (i <= 5 && paginateAction == '') {
      pageNumbers.push(i);
    } else if (paginateAction == 'next') {
      if (i > paginateInitial && i <= paginateInitial + 5) {
        pageNumbers.push(i);
      }
    } else if (paginateAction == 'prev') {
      console.log(paginateInitial, 'prev');
      if (i <= paginateInitial) {
        pageNumbers.push(i);
      }
    }
  }

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const action = (val) => {
    if (val == 'next') {
      let num = paginateInitial + 5;
      setPaginateInitial(num);
      setPaginateAction(val);
    } else {
      let numTwo = paginateInitial - 5;

      setPaginateInitial(numTwo);
      setPaginateAction(numTwo == 0 ? '' : val);
    }
  };

  const PaginateButton = pageNumbers.map((number, Index) => {
    return (
      <li key={number} className="page-item">
        <a onClick={() => paginate(number)} className={`page-link ${number == currentPage ? ' active-paginate' : ''}`}>
          <span className={` ${number == currentPage ? ' font-color-active' : ''}`}>{number}</span>
        </a>
      </li>
    );
  });

  const gitRequstHistory = (project_id) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios.get(`material/request-history/${company_id}/${project_id}`).then((res) => {
      console.log(res.data.response.data, 'sample data');
      setRequestHistory(res.data.response.data);
    });
    // setRequestHistory(pr_data);
  };

  const getproject = (project_id) => {
    $axios
      .post('material/project/list', {
        project_id,
        company_id: company_id,
      })
      .then((res) => {
        console.log(res.data.response, 'material');
        setlistOfMaterial(res.data.response);
        setcurrentPage(1);
        setDataRequest(res.data.response);
        setLoading(false);

        let arr = [];

        res.data.response.forEach((data) => {
          arr.push(data.MaterialName);
        });

        setmaterialAll(arr);
        // setissue(res.data.notification);
        //
        // console.log(res.data.notification);
        // console.log(notif);
      })
      .catch((error) => {
        console.log('projectdetails/Material.js', ' ', 'line 196', error);
      });
  };

  // const gitmaterialdropdown = () => {
  //   axios
  //     .get(IP + "material/list/all", {
  //       headers: {
  //         "Content-Type": "application/vnd.myapp.type+json",
  //         // Authorization: "Bearer " + accessToken,

  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       setmaterialAll(res.data.response);

  //       let arr = [];
  //       for (var i in res.data.response) {
  //         arr.push({
  //           label: res.data.response[i],
  //           value: res.data.response[i],
  //         });
  //       }
  //       setmaterialAll(arr);
  //     })
  //     .catch((error) => {
  //       console.log("projectdetails/Material.js", " ", "line 213", error);
  //     });
  // };

  const clickPrint = (project_id, pr_number) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios.get(`material/request-history/details/${company_id}/${project_id}/${pr_number}`).then((res) => {
      // setRequestHistory(res.data.response.data);?

      setpurchaseRequest(res.data.response.data);
      console.log(res.data.response.data, 'purchaseRequest');
      // setPreparer(res.data.response.preparer);
      // setRequestDate(res.data.response.date_requested);
      // setPrNumber(res.data.response.pr_num);
      // setNotes(res.data.response.notes);
      // setApprover(res.data.response.approver);
      // setPosition(res.data.response.position);
      // setDateApproved(res.data.response.date_approved);
      // setPreparerPosition(res.data.response.preparer_position);
    });

    setActive(3);
  };

  const materialoption = materialAll.map((item, Index) => {
    return <option key={Index} value={item}></option>;
  });

  const add = () => {
    let arr = [...addMaterial];
    arr.push({
      Description: '',
      Unit: '',
      Qty: '',
    });
    setdescription(false);
    setqty(false);
    setunit(false);
    setAddMaterial(arr);
  };

  const QuantityOnChange = (e, materialIndex) => {
    let arr = [...listOfMaterial];
    let req = [...dataRequest];
    let Index = arr.findIndex((a) => a.index == materialIndex);
    arr[Index].NoOfOrders = parseFloat(e.target.value);
    let reqIndex = req.findIndex((a) => a.index == materialIndex);
    req[materialIndex].NoOfOrders = parseFloat(e.target.value);

    setlistOfMaterial(arr);
    setDataRequest(req);
  };

  const clickEdit = (index) => {
    let arr = [...requestHistory];

    arr[index]['edit'] = true;

    setRequestHistory(arr);
  };

  const remarks = (e, index) => {
    let arr = [...requestHistory];

    arr[index]['remarks'] = e.target.value;

    setRequestHistory(arr);
  };

  const saveDateDelivered = (e, index, id, pr_num, remarks) => {
    console.log(id, pr_num, remarks, props.project_id);
    const company_id = parseInt(localStorage.getItem('company_id'));
    // if (e.key == "Enter") {
    let data = {
      id: id,
      pr_num: pr_num,
      updates: {
        remarks: remarks,
      },
    };

    $axios
      .put(`material/request-history/${company_id}/${props.project_id}`, {
        ...data,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 200) {
          let arr = [...requestHistory];

          arr[index]['edit'] = false;

          setRequestHistory(arr);
          gitRequstHistory(props.project_id);
        }
      });
    // }
  };

  const PrHistory = requestHistory.map((data, Index) => {
    let color = '#25C6E3';
    if (data['approval_status'] == 'Approved') {
      color = '#3A7D21';
    } else if (data['approval_status'] == 'Disapproved') {
      color = '#B42A19';
    }
    return (
      <tr key={Index}>
        <td>{Index + 1}</td>
        <td>{data.pr_number}</td>
        <td>{data.preparer}</td>
        <td>{data.request_date}</td>
        <td>
          <CCol style={{ padding: '0' }}>
            <CRow>
              <CCol xl="10">
                <CTextarea
                  onChange={(e) => remarks(e, Index)}
                  onBlur={(e) => saveDateDelivered(e, Index, data.id, data.pr_number, data.remarks)}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      saveDateDelivered(e, Index, data.id, data.pr_number, data.remarks);
                    }
                  }}
                  onClick={(e) => {
                    console.log(data['remarks']);
                  }}
                  value={data['remarks']}
                  disabled={data.edit != true}
                ></CTextarea>
              </CCol>
              <CCol xl="2">
                <FaPencilAlt onClick={() => clickEdit(Index)} />
              </CCol>
            </CRow>
          </CCol>
        </td>
        <td style={{ color: color }}>{data['approval_status']}</td>
        {/* <td>Test</td> */}
        <td>
          <CButton variant="outline" block color="aimhi-primary" onClick={() => clickPrint(props.project_id, data.pr_number, data.preparer, data.request_date, data.notes, data.approver, data.position, data.data_approved)}>
            Print
          </CButton>
        </td>
      </tr>
    );
  });

  const listmaterial = paginateArray().map((item, Index) => {
    console.log(item, 'janrey');
    let progress = (parseInt(item.TotalOrder) / parseInt(item.TotalQuantity)) * 100;

    let color = '#3a7d21';
    if (progress >= 50 && progress < 75) {
      color = '#3a7d21';
    } else if (progress >= 75 && progress < 101) {
      color = '#f2e442';
    }

    // else if (progress >= 90 && progress < 101) {
    //   color = '#b6451d';
    // }
    else if (progress > 100) {
      color = '#b42a19';
    }

    return (
      <CCol style={{ marginTop: '10px', padding: '0px' }} key={Index}>
        <CRow>
          <CCol xs="12" sm="12" md="12" lg="4">
            <CRow>
              {item.MaterialName}
              {/* <RecommenderPopUp>
                <p>Request this material</p>
              </RecommenderPopUp> */}
            </CRow>
          </CCol>
          <CCol xs="12" sm="12" md="12" lg="6">
            <div className="progress" style={{ borderRadius: '10px', height: '20px' }}>
              <div
                className="progress-bar"
                style={{
                  width: progress + '%',
                  background: color,
                  height: '20px',
                }}
              >
                {item.TotalOrder}
              </div>
              <span className="progress-total-val">
                {item.TotalOrder}/{item.TotalQuantity}
              </span>
            </div>
          </CCol>
          <CCol xs="12" sm="12" md="12" lg="2">
            <CInput
              type="number"
              size="sm"
              className="input_number_spin"
              onChange={(e) => QuantityOnChange(e, item.index)}
              onWheel={(e) => e.target.blur(e)}
              // onBlur={(e) => onblur(e, Index)}
              value={item.NoOfOrders}
              step="any"
            />
          </CCol>
        </CRow>
      </CCol>
    );
  });

  const DescOnChange = (e, Index) => {
    let arr = [...addMaterial];

    arr[Index].Description = e.target.value;

    if (e.target.value !== '') {
      setdescription(true);
    } else {
      setdescription(false);
    }

    setAddMaterial(arr);
  };

  const UnitOnChange = (e, Index) => {
    let arr = [...addMaterial];
    arr[Index].Unit = e.target.value;

    if (e.target.value !== '') {
      setunit(true);
    } else {
      setunit(false);
    }
    setAddMaterial(arr);
  };

  const QtyOnChange = (e, Index) => {
    let arr = [...addMaterial];
    arr[Index].Qty = e.target.value;

    if (e.target.value !== '') {
      setqty(true);
    } else {
      setqty(false);
    }
    setAddMaterial(arr);
  };

  const submitmaterial = () => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      // .post("http://localhost:5000/material/add", {
      .post('material/project/add', {
        Materials: addMaterial,
        project_id: projectId,
        company_id: company_id,
      })
      .then(function (response) {
        setlistOfMaterial(response.data.response);
      });

    let inialize = [
      {
        Description: '',
        Unit: '',
        Qty: '',
      },
    ];

    setModal(false);
  };

  // //John's Code
  // const CreateRequest = () => {
  //   let matarr = [...dataRequest];
  //   let data = matarr.filter((a) => a.NoOfOrders > 0);

  //   console.log(data, 'data');

  //   const tableHtml = `
  //   <div style="overflow: auto; max-height: 300px;">
  //     <table>
  //       <tr>
  //         <th>Material Name</th>
  //         <th>No. of Orders</th>
  //       </tr>
  //       ${data
  //         .map(
  //           (item) => `
  //         <tr>
  //           <td>${item.MaterialName}</td>
  //           <td><input type="number" value="${item.NoOfOrders}" id="input-${item.material_id}" /></td>
  //         </tr>
  //       `,
  //         )
  //         .join('')}
  //     </table>
  //     </div>
  //   `;
  //   if (data.length === 0) {
  //     Swal.fire({ title: 'Request a material first.', icon: 'error' });
  //   } else {
  //     Swal.fire({
  //       title: 'Material Orders',
  //       html: tableHtml,
  //       icon: "info",
  //       width: 600,
  //       showCancelButton: true,
  //       confirmButtonText: "Save",
  //       confirmButtonColor: "#23303d",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         let arr = [];
  //         for (var i in data) {
  //           const inputValue = document.getElementById(`input-${data[i].material_id}`).value;
  //           console.log(inputValue, "inputValue"); // Console log the inputValue inside the loop
  //           arr.push({
  //             material_id: data[i].material_id,
  //             sow_id: data[i].sow_id,
  //             psow_id: data[i].psow_id,
  //             total_order: parseFloat(inputValue),
  //           });
  //         }

  //         axios
  //           .post(IP + "material/request", {
  //             user_id: user_id,
  //             purchase_request: arr,
  //             token: accessToken,
  //             project_id: projectId,
  //             company_id: company_id,
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${accessToken}`,
  //             },
  //           })
  //           .then(function (response) {
  //             console.log(response.data.response, "response");
  //             console.log(arr, "arr");
  //             setActive(2);
  //             getproject(props.project_id);
  //             gitRequstHistory(props.project_id);
  //             requestMaterialNotifications(company_id, projectId);
  //             Swal.fire("Saved!", "", "success");
  //           });
  //       } else if (result.isDenied) {
  //         Swal.fire("Changes are not saved", "", "info");
  //       }
  //     });
  //   }
  // };

  const send = () => {
    // const receiver = email
    $axios
      .post('material/sentmail', {
        receiver: email,
        purchase_request: purchaseRequest,
      })
      .then(function (response) {
        if (response.data.status == 1) {
          setmodalEmail(false);
          Swal.fire({
            // position: "top-end",
            icon: 'success',
            title: 'Email already sent',
            showConfirmButton: false,
          });
        } else {
        }
      });
  };

  const search = debounce((e) => {
    setSearchValue(e);

    // console.log(arr, 'array search', e);
    // let filterData = arr.filter((a) => a.MaterialName.toLowerCase().includes(e.toLowerCase()));
    // // setpostperPage(filterData.length);
    // setlistOfMaterial(filterData);
  }, 30);

  const AddMaterialModal = addMaterial.map((item, Index) => {
    return (
      <tr key={Index}>
        <td>
          <Select size="sm" style={{ border: 'none' }} options={materialAll} />
          {/* <CSelect
            size="sm"
            className="form-boderless"
            onChange={(e) => DescOnChange(e, Index)}
            value={item.Description}
          >
            <option value="">Select...</option
            {materialoption}
          </CSelect> */}
        </td>
        <td>
          <CSelect size="sm" className="form-boderless" onChange={(e) => UnitOnChange(e, Index)} value={item.Unit}>
            <option value="">Select Unit</option>
            <option value="kls.">kls.</option>
            <option value="Lgts.">Lgts.</option>
            <option value="pcs.">pcs.</option>
            <option value="set.">set.</option>
          </CSelect>
        </td>
        <td>
          <CInput type="number" size="sm" className="form-boderless" onChange={(e) => QtyOnChange(e, Index)} value={item.Qty} step="any" />
        </td>
      </tr>
    );
  });

  if (clickPrintNotif == true) {
    return (
      <CCol>
        <span style={{ fontSize: '25px', fontWeight: 'Bold' }}>Please use print request button to print!!</span>
      </CCol>
    );
  }

  const GetUsersById = (user_id) => {
    $axios
      .post('account/user/info', {
        user_id,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status == 1) {
          // setFullname(response.data.response.full_name);

          setDepartment(response.data.response.department);
        }
      });
  };

  const getCompanyData = (company_id) => {
    $axios
      .get(`company/info/${company_id}`, {
        company_id,
      })
      .then(function (response) {
        if (response.data.status == 1) {
          setCompanyName(response.data.response.name);

          setLocation(response.data.response.location);
          setBusinessEmail(response.data.response.business_email);
          setBusinessPhone(response.data.response.contact_num);

          setcompanyProfilePicture(response.data.response.company_pic);
          console.log(response, 'hello');
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      });
  };

  const handleNavSubmit = () => {
    let matarr = [...dataRequest];
    let data = matarr.filter((a) => a.NoOfOrders > 0);
    console.log(data, 'matarr1');
    console.log('notes', data[i]?.items?.notes);
    let submit = [];
    for (var i in data) {
      submit.push({
        material_id: data[i].material_id,
        sow_id: data[i].sow_id,
        psow_id: data[i].psow_id,
        total_order: data[i].NoOfOrders,
        unit: data[i].items.unit,
        pm_id: data[i].pm_id,
        notes: data[i].items.notes,
      });
    }

    $axios
      .post('material/request', {
        user_id: user_id,
        purchase_request: submit,
        token: accessToken,
        project_id: projectId,
        company_id: company_id,
      })
      .then(function (response) {
        console.log(response.data.response, 'response');
        console.log(submit, 'submit');
        setActive(2);
        setActiveTab('');
        getproject(props.project_id);
        gitRequstHistory(props.project_id);
        requestMaterialNotifications(company_id, projectId);
        Swal.fire('Saved!', '', 'success');
      });
  };

  const handleNavClick = (tabName) => {
    if (tabName === '') {
      const updatedListOfMaterial = listOfMaterial.map((item) => ({
        ...item,
      }));
      setSearchValue('');
      setlistOfMaterial(updatedListOfMaterial);
      setDataRequest(updatedListOfMaterial);
      // setpostperPage(10);
    }
    setActiveTab(tabName);
  };

  const editQuantity = (index) => {
    console.log('Editing...');
    let arr = [...createRequest];
    arr[index]['edit_quantity'] = true;
    setCreateRequest(arr);
  };

  const quantityChange = (e, index, tx_id) => {
    console.log('Quantity Change');
    let arr = [...createRequest];
    let originalQty = arr[index].items.qty;
    arr[index].items.qty = e.target.value;
    setCreateRequest(arr);

    // Store the original quantity in the state
    setOriginalQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: originalQty,
    }));

    // let arr = [...createRequest];
    // let originalQty = arr[index].items.qty;

    // arr[index].items.qty = e.target.value;
    // arr[index]['edit_quantity'] = false;
    // setCreateRequest(arr);

    // let matarr = [...dataRequest];
    // matarr[index].NoOfOrders = parseFloat(e.target.value);
    // setDataRequest(matarr);
  };
  //added this function to handle onBlur event.
  const handleBlur = (e, index) => {
    console.log('On blur');
    let arr = [...createRequest];
    let originalQty = arr[index].items.qty; // Store the original quantity before updating
    let newValue = e.target.value.trim();
    console.log('New Value:', newValue);
    if (newValue === '') {
      console.log('originalQty:', originalQuantities[index]);
      arr[index].items.qty = originalQuantities[index]; // Revert to the original quantity
      setCreateRequest(arr);
    }

    // Optionally, you can update the corresponding value in dataRequest as well:
    let matarr = [...dataRequest];
    matarr[index].NoOfOrders = parseFloat(arr[index].items.qty);
    setDataRequest(matarr);
  };

  // const editUnits = (index) => {
  //   let arr = [...createRequest];
  //   arr[index]['edit_units'] = true;
  //   setCreateRequest(arr);
  // };

  // const unitChange = (e, index, tx_id) => {
  //   let arr = [...createRequest];
  //   arr[index].items.unit = e.target.value;

  //   arr[index]['edit_units'] = false;
  //   setCreateRequest(arr);
  // };

  const editNotes = (index) => {
    let arr = [...createRequest];
    arr[index]['edit_notes'] = true;
    setCreateRequest(arr);
  };

  const noteChange = (e, index, tx_id) => {
    let arr = [...createRequest];
    arr[index].items.notes = e.target.value;
    // arr[index]['edit_notes'] = false;
    setCreateRequest(arr);
  };

  const noteOnBlur = (index) => {
    let arr = [...createRequest];
    // arr[index].items.notes = e.target.value;
    arr[index]['edit_notes'] = false;
    setCreateRequest(arr);
  };

  const CreateRequest = () => {
    let matarr = [...dataRequest];
    let data = matarr.filter((a) => a.NoOfOrders > 0);
    console.log(data, 'metarr_data');
    if (data.length === 0) {
      Swal.fire({ title: 'Request a material first.', icon: 'error', confirmButtonColor: '#23303D' });
    } else {
      let arr = [];
      for (var i in data) {
        arr.push({
          approver: '',
          approver_position: '',
          date_approved: '',
          date_requested: '',
          pr_num: '',
          preparer: '',
          preparer_position: '',
          items: {
            item: data[i].MaterialName,
            notes: data[i].notes || '',
            pr_id: '',
            qty: data[i].NoOfOrders,
            tx_id: '',
            unit: data[i].Unit,
          },
          material_id: data[i].material_id,
          pm_id: data[i].pm_id,
          sow_id: data[i].sow_id,
          psow_id: data[i].psow_id,
          total_order: data[i].NoOfOrders,
          NoOfOrders: data[i].NoOfOrders,
        });
      }

      console.log(arr, 'arr');
      setCreateRequest(arr);
      setDataRequest(arr);
      handleNavClick('Nav1');
    }
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <CTabs>
            <CNav variant="tabs" style={{ borderWidth: '5px' }}>
              <CNavItem>
                <CNavLink className={active == 1 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(1)}>
                  Material List
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  className={active == 2 || active == 3 ? 'nav-design-active' : 'nav-design'}
                  onClick={() => {
                    setActive(2);
                    handleNavClick('');
                  }}
                >
                  Request History
                </CNavLink>
              </CNavItem>

              {permission['view'][7] === 1 && (
                <CNavItem>
                  <CNavLink
                    className={active == 4 ? 'nav-design-active' : 'nav-design'}
                    onClick={() => {
                      setActive(4);
                      handleNavClick('');
                    }}
                  >
                    Stock Balance
                  </CNavLink>
                </CNavItem>
              )}

              {permission['view'][8] === 1 && (
                <CNavItem>
                  <CNavLink
                    className={active == 5 ? 'nav-design-active' : 'nav-design'}
                    onClick={() => {
                      setActive(5);
                      handleNavClick('');
                    }}
                  >
                    Material Utilization
                  </CNavLink>
                </CNavItem>
              )}
            </CNav>
            {active === 1 && activeTab !== 'Nav1' && (
              <div>
                <CCol style={{ padding: '0px' }}>
                  <CRow>
                    <CCol xs="12" sm="12" md="4" lg="4" style={{ marginTop: '20px' }}>
                      <label className="form-label" for="exampleDataList">
                        Search
                      </label>
                      <input value={searchValue} type="search" className="form-control" id="exampleDataList" list="datalistOptions" placeholder="Type material name to search...." onChange={(e) => search(e.target.value)} />
                      <datalist id="datalistOptions">{materialoption}</datalist>
                    </CCol>
                    <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                      <CRow>
                        <CCol xs="12" sm="12" md="6" lg="10"></CCol>

                        <CCol xs="12" sm="12" md="12" lg="2">
                          {permission['update'][0] == 1 && (
                            <CButton block color="aimhi-primary" size="sm" onClick={() => CreateRequest()}>
                              Create request
                            </CButton>
                          )}
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol xs="12" sm="12" md="12" lg="12">
                      {listmaterial}
                      <CCol md="12" style={{ padding: '0', marginTop: '20px' }}>
                        <nav className="float-left">
                          <CPagination activePage={currentPage} pages={Math.ceil(listOfMaterial.filter((a) => a.MaterialName.toLowerCase().includes(searchValue.toLowerCase())).length / 10)} onActivePageChange={(e) => setcurrentPage(e)} />
                          {/*<ul className="pagination">*/}
                          {/*  <li className="page-item">*/}
                          {/*    <a onClick={() => action('prev')} className="page-link">*/}
                          {/*      Prev*/}
                          {/*    </a>*/}
                          {/*  </li>*/}
                          {/*  {PaginateButton}*/}
                          {/*  <li className="page-item">*/}
                          {/*    <a onClick={() => action('next')} className="page-link">*/}
                          {/*      Next*/}
                          {/*    </a>*/}
                          {/*  </li>*/}
                          {/*</ul>*/}
                        </nav>
                      </CCol>
                    </CCol>
                  </CRow>
                </CCol>
              </div>
            )}

            {active == 1 && activeTab === 'Nav1' && (
              <>
                <div>
                  <CCol style={{ padding: '0px' }}>
                    <CRow>
                      <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                        <CRow>
                          <CCol xs="12" sm="12" md="12" lg="8"></CCol>
                          <CCol xs="12" sm="12" md="12" lg="2">
                            {permission['update'][0] == 1 && (
                              <CButton variant="outline" style={{ backgroundColor: '#23303d', color: 'white' }} size="sm" block onClick={() => handleNavSubmit()}>
                                <span>Submit</span>
                              </CButton>
                            )}
                          </CCol>
                          <CCol xs="12" sm="12" md="12" lg="2">
                            {permission['update'][0] == 1 && (
                              <CButton variant="outline" style={{ backgroundColor: '#6e7d88', color: 'white' }} size="sm" block onClick={() => handleNavClick('')}>
                                <span>Cancel</span>
                              </CButton>
                            )}
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <div>
                          <table className="table table-bordered" style={{ marginTop: '20px' }}>
                            <tr style={{ fontWeight: 'bold' }}>
                              <td colSpan={6} style={{ textAlign: 'center' }}>
                                <img src={companyProfilePicture} style={{ width: '100px', height: '100px' }} />
                                <div>
                                  <span>{companyName}</span>
                                </div>
                                <div>
                                  <span>{location}</span>
                                </div>
                                <div style={{ marginTop: '15px' }}>
                                  <span>Purchase Requisition</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>Department :</span>
                                  <span style={{ marginLeft: '5px' }}>{department}</span>
                                </div>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>Project Name :</span>
                                  <span style={{ marginLeft: '5px' }}>{props.project_name}</span>
                                </div>
                              </td>
                              <td colSpan={3} style={{ textAlign: 'right' }}>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>Date Requested :</span>
                                  <span style={{ marginLeft: '5px' }}>{props.purchaserequest?.date_requested ?? ''}</span>
                                </div>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>Pr Number :</span>
                                  <span style={{ marginLeft: '5px' }}>{props.purchaserequest?.pr_num ?? ''}</span>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table className="table table-bordered" style={{ marginTop: '20px' }}>
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Item Description</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Notes (optional)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {createRequest.map((item, Index) => {
                                return (
                                  <tr key={Index}>
                                    <td>{Index + 1}</td>
                                    <td>{item.items.item}</td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.items.qty} // Display the current quantity in the text field
                                        onChange={(e) => quantityChange(e, Index, item.items.tx_id)}
                                        onBlur={(e) => handleBlur(e, Index)}
                                        style={{ width: 80, border: '1px solid #707070c0' }}
                                      />
                                    </td>

                                    {/* <td onClick={() => editQuantity(Index)}>{item['edit_quantity'] == true ? <CTextarea onBlur={(e) => quantityChange(e, Index, item.items.tx_id)}></CTextarea> : <span>{item.items.qty}</span>}</td> */}
                                    <td
                                    // onClick={() => editUnits(Index)}
                                    >
                                      {/* {item['edit_units'] == true ? <CTextarea onBlur={(e) => unitChange(e, Index, item.items.tx_id)}></CTextarea> :  */}
                                      <span>{item.items.unit}</span>
                                      {/* } */}
                                    </td>
                                    <td onClick={() => editNotes(Index)} style={{ cursor: 'pointer' }}>
                                      {item['edit_notes'] == true ? <CTextarea value={item.items.notes} onChange={(e) => noteChange(e, Index, item.items.tx_id)} onBlur={(e) => noteOnBlur(Index)}></CTextarea> : <span>{item.items.notes}</span>}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <table className="table table-bordered">
                            <tbody style={{ textAlign: 'center' }}>
                              <tr style={{ fontSize: '12px' }}>
                                <td>Requested by:</td>
                                <td>Prepared by:</td>
                                <td style={{ width: '250px' }}>Notes:</td>
                                <td>Date Approved:</td>
                                <td colSpan={2}>Approved by:</td>
                              </tr>
                              <tr style={{ fontSize: '12px' }}>
                                <td style={{ verticalAlign: 'bottom', height: '60px' }}>
                                  <span className="pointer"> </span>
                                </td>
                                <td style={{ verticalAlign: 'bottom' }}>{/* {category == 'Fuel' ? props.fuel?.preparer : props.equipment?.preparer} */}</td>
                                <td>{/* {category == 'Fuel' ? props.fuel?.notes : props.equipment?.notes} */}</td>
                                <td rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                                  <span>{/* {category == 'Fuel' ? props.fuel?.date_approved : props.equipment?.date_approved} */}</span>
                                </td>
                                <td colSpan={2} rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                                  {/* {category == 'Fuel' ? props.fuel?.approver : props.equipment?.approver} */}
                                  <div>
                                    <span>{/* {category == 'Fuel' ? props.fuel?.approver_position : props.equipment?.approver_position} */}</span>
                                  </div>
                                </td>
                              </tr>
                              <tr style={{ fontSize: '12px' }}>
                                <td>
                                  <span className="pointer"> </span>
                                </td>
                                <td>{/* {category == 'Fuel' ? props.fuel?.preparer_position : props.equipment?.preparer_position} */}</td>
                              </tr>
                            </tbody>
                          </table>
                          <CCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ padding: '0px' }}>
                            <ThingsToConsider project_id={props.project_id} handleRedirectOnLowStock={handleRedirectOnLowStock} />
                          </CCol>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </div>
              </>
            )}
            {active == 3 && (
              <CCol style={{ padding: '0px' }}>
                <CRow>
                  <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                    <CRow>
                      <CCol xs="12" sm="12" md="12" lg="8"></CCol>
                      <CCol xs="12" sm="12" md="12" lg="2">
                        {permission['update'][0] == 1 && (
                          <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={() => handlePrint(setprint(true))}>
                            <span>Print request</span>
                          </CButton>
                        )}
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="2">
                        {permission['update'][0] == 1 && (
                          <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={() => setmodalEmail(true)}>
                            <span>Email request</span>
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <PrintPurchaseRequest
                      ref={componentRef}
                      purchaserequest={purchaseRequest}
                      print={print}
                      project_name={props.project_name}
                      project_id={props.project_id}

                      // preparer={preparer}
                      // request_date={requestDate}
                      // pr_number={prNumber}
                      // notes={notes}
                      // approver={approver}
                      // position={position}
                      // date_approved={dateApproved}
                      // preparer_position={preparerPosition}
                    />
                  </CCol>
                </CRow>
              </CCol>
            )}

            {active == 2 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>PR Number</th>
                    <th>Preparer</th>
                    <th>Request Date</th>
                    <th>Remarks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{PrHistory}</tbody>
              </table>
            )}

            {active == 4 && (
              <div style={{ marginTop: '12px' }}>
                <MaterialUtilizationProject project_id={projectId} />
              </div>
            )}
            {active == 5 && (
              <div style={{ marginTop: '12px' }}>
                <StockBalance from_project_module={true} projectID={projectId} />
              </div>
            )}
          </CTabs>
        </CCol>

        <CCol xs="12" sm="12" md="12" lg="12" xl="3">
          {active != 3 && <ThingsToConsider project_id={props.project_id} handleRedirectOnLowStock={handleRedirectOnLowStock} />}
        </CCol>
      </CRow>

      <CModal size="lg" show={modal} onClose={() => setModal(!modal)} style={{ borderRadius: '10px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>Add New Material</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: '250px' }}>Description</th>
                <th>Unit</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>{AddMaterialModal}</tbody>
          </table>

          <CButton
            // color="link"
            onClick={() => add()}
            disabled={!unit || !qty || !description}
          >
            <u>Add More</u>
          </CButton>
          {/* <span onClick={() => add()}>Add</span> */}
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          {/* <CButton color="primary" onClick={() => setLarge(!large)}>
            Do Something
          </CButton>{" "} */}
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton variant="outline" block color="aimhi-textlight-neutral" size="sm" onClick={() => submitmaterial()} disabled={!qty || !unit || !description}>
              Submit
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
      <CModal show={modalEmail} onClose={() => setmodalEmail(!modalEmail)} style={{ borderRadius: '5px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>SEND E-MAIL</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12">
            <CFormGroup row>
              <CLabel sm="2" col="sm" htmlFor="input-small">
                TO:
              </CLabel>
              <CCol sm="6">
                <CInput size="sm" type="email" id="input-small" className="input-sm" placeholder="example@gmail.com" value={email} onChange={(e) => setemail(e.target.value)} />
              </CCol>
            </CFormGroup>
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton block color="aimhi-primary" size="sm" onClick={() => send()} disabled={email == ''}>
              SEND
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Materials;
