import React, { lazy, useState, useEffect } from 'react';
import { CButton, CCol, CRow, CListGroupItem, CListGroup, CForm, CInputRadio, CFormGroup, CLabel, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle, CCallout, CInput, CButtonClose, CAlert, CTooltip } from '@coreui/react';
import { useSelector } from 'react-redux';
import { FaAngleDown, FaAngleUp, FaExclamationTriangle, FaArrowRight, FaSun, FaTemperatureHigh, FaBoxOpen } from 'react-icons/fa';
import Notifications from '../../websocket/notifications';
import nba from '../../assets/img/nba.png';
import recommender from '../../assets/img/ai-suggest2.png';
import ai from '../../assets/img/ai_2.png';
import ai2 from '../../assets/img/ai-suggest2.png';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import IP from '../../StoreGlobal/IP';
import $axios from 'src/utils/axios';
import { getCatchupRecommendation, getDiversionRecommendation, getCatchupWeatherRecommendation, getMaterialRecommendationsList } from 'src/request/Schedule';
import DatePicker from 'react-datepicker';
import { MdOutlinePostAdd, MdOutlineClear } from 'react-icons/md';
import Select from 'react-select';
import './widgets.css';
import { BsFillCloudDrizzleFill, BsCloudRainFill, BsCloudLightningRainFill } from 'react-icons/bs';
import { getFeatures } from 'src/request/Features';
import { findAllowedFeature } from 'src/utils/findAllowedFeature';
import { workItemStatusNotification } from 'src/redux/actions/notifications';
const defaultNotififcation = [
  {
    issue: 'no notifications yet',
    module: 'material',
    // threshold: 50,
    severity: 'green',
  },
];

const recommendationsData = [
  {
    issue: 'Base on the current productivity there is a recommended catch-up plan. Review?',
  },
];

const recommendationsWeatherData = [
  {
    issue: 'Review recommended catchup plan due to weather delays',
  },
];
const ThingsToConsider = (props) => {
  let accessToken = localStorage.getItem('token');
  const [issue, setissue] = useState([]);
  const [showMore, setShowMore] = useState(1);
  const rdx_module = useSelector((state) => state.module);
  const rdx_materialNotif = useSelector((state) => state.materialNotifications);
  const rdx_equipmentNotif = useSelector((state) => state.equipmentNotifications);
  const rdx_manpowerNotif = useSelector((state) => state.manpowerNotifications);
  const rdx_sowNotif = useSelector((state) => state.sowNotifications);
  const redux_module = useSelector((state) => state.module);
  const features = useSelector((state) => state.features);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionsWeather, setSelectedOptionsWeather] = useState({});
  const [expandedIndexRecommendations, setExpandedIndexRecommendations] = useState(null);
  const [expandedIndexRecommendationsWeather, setExpandedIndexRecommendationsWeather] = useState(null);
  const [expandedIndexDependency, setExpandedIndexDependency] = useState(null);
  const [expandedIndexDiversion, setExpandedIndexDiversion] = useState(null);
  const [expandedIndexCatchup, setExpandedIndexCatchup] = useState(null);
  const [expandedIndexCatchupWeather, setExpandedIndexCatchupWeather] = useState(null);
  const [expandAlterWeather, setExpandAlterWeather] = useState(false);
  const [expandUndoRecommendation, setExpandUndoRecommendation] = useState(false);
  const [recommendationsData, setRecommendationsData] = useState([
    {
      issue: 'Base on the current productivity there is a recommended catch-up plan. Review?',
    },
  ]);
  const [recommendationsWeatherData, setRecommendationsWeatherData] = useState([
    {
      issue: 'Review recommended catchup plan due to weather delays',
    },
  ]);
  const [recommendationsDataDependency, setRecommendationsDataDependency] = useState([
    {
      issue: 'We have some recommended dependencies for you. Kindly check it out.',
    },
  ]);
  const [recommendationsDataCatchUp, setRecommendationsDataCatchup] = useState([]);
  const [recommendationsDataDiversion, setRecommendationsDataDiversion] = useState([]);
  const [recommendationsDataCatchUpWeather, setRecommendationsDataCatchUpWeather] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const Swal = require('sweetalert2');
  const [modal, setModal] = useState(false);
  const [catchUpData, setCatchupData] = useState([]);
  const [catchUpDataWeather, setCatchupDataWeather] = useState([]);
  const [catchupPlanAvailable, setCatchupPlanAvailable] = useState(false);
  const [catchupPlanAvailableWeather, setCatchupPlanAvailableWeather] = useState(false);
  const [modalItem, setModalItem] = useState(0);
  const company_id = parseInt(localStorage.getItem('company_id'));
  const user_id = parseInt(localStorage.getItem('user_id'));
  const [selectedPhase, setSelectedPhase] = useState([]);
  const [selectedWorkItem, setSelectedWorkItem] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [index, setIndex] = useState(null);
  const [altIndex, setAltIndex] = useState(null);
  const [originalDataDiversion, setOriginalDataDiversion] = useState([]);
  const [rainIntensity, setRainIntensity] = useState(0);
  const [heatIndex, setHeatIndex] = useState(0);
  const [rainTimeStamp, setRainTimeStamp] = useState('');
  const [isCatchUpRecoAllowed, setIsCatchUpRecoAllowed] = useState(false);
  const [materialRequestData, setMaterialRequestData] = useState([]);
  const [workItemStatus, setWorkItemStatus] = useState([]);
  // useEffect observables for notifications
  const observables = [rdx_module, rdx_materialNotif, rdx_equipmentNotif, rdx_manpowerNotif, rdx_sowNotif];

  // useEffect(() => {
  //   setissue(defaultNotififcation);
  // }, []);
  useEffect(() => {
    if (rdx_module == 'project-scope-of-work') {
      $axios
        .post('project/weather/status', { project_id: props.project_id })
        .then((response) => {
          if (response.data[1] === 200) {
            setRainIntensity(response.data[0].weather_status);
            setRainTimeStamp(response.data[0].weather_time_stamp);
            setHeatIndex(response.data[0].heat_index);
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
    }
  }, []);

  useEffect(() => {
    if (rdx_module === 'schedule' || rdx_module === 'project-scope-of-work') {
      if (props.project_id)  {
      getCatchupRecommendation(props.project_id)
        .then((res) => {
          if (res.status === 200) {
            setRecommendationsDataCatchup(res.data.data);
            setCatchupPlanAvailable(true);
          }
        })
        .catch((error) => {
          // console.error(error);
          setCatchupPlanAvailable(false);
          setRecommendationsDataCatchup([]);
        });
      }
    }
  }, [props.project_id]);

  useEffect(() => {
    if (rdx_module == 'project-materials') {
      getMaterialRecommendationsList(props.project_id).then((res) => {
        if (res.data.status === 200) {
          setMaterialRequestData(res?.data?.data);
        }
      });
      if (rdx_materialNotif !== null) {
        setissue(rdx_materialNotif.list);
      } else {
        setissue(defaultNotififcation);
      }
    } else if (rdx_module == 'project-scope-of-work') {
      if (rdx_sowNotif && rdx_sowNotif.list && rdx_sowNotif.list.length !== 0) {
        //GIO
        workItemStatusNotification(props.project_id)
        .then((res) => {
          setWorkItemStatus(res);
        })
        // setissue(rdx_sowNotif.list);
      } else {
        setissue(defaultNotififcation);
      }
    }
    // else {
    //   setissue(defaultNotififcation);
    // }

    // if (redux_notifications !== null) {
    //   const notif_modules = ["project-materials", "project-scope-of-work"];
    //
    //   if (
    //     notif_modules.includes(rdx_module) &&
    //     redux_notifications.list.length > 0
    //   ) {
    //     setissue(redux_notifications.list);
    //   } else {
    //     setissue(defaultNotififcation);
    //   }
    // }
  }, observables);

  useEffect(() => {
    if (rdx_module === 'project-scope-of-work')
    {
      if(props.project_id) {
        getDiversionRecommendation(props.project_id)
          .then((res) => {
            if (res.status === 200) {
              setRecommendationsDataDiversion(res.data.data);
              // setCatchupPlanAvailable(true);
            }
          })
          .catch((error) => {
            // console.error(error);
            console.log('No recommendation');
            // setCatchupPlanAvailable(false);
            setRecommendationsDataDiversion([]);
          });
      }
    }
  }, [props.project_id]);

  useEffect(() => {
    if (rdx_module === 'schedule' || rdx_module === 'project-scope-of-work') {
      if(props.project_id) {
        getCatchupWeatherRecommendation(props.project_id)
          .then((res) => {
            if (res.status === 200) {
              // setRecommendationsDataDiversion(res.data.data);
              setRecommendationsDataCatchUpWeather(res.data.data);
              setCatchupPlanAvailableWeather(true);

              // setCatchupPlanAvailable(true);
            }
          })
          .catch((error) => {
            // console.error(error);
            console.log('No recommendation');
            // setCatchupPlanAvailable(false);
            setRecommendationsDataCatchUpWeather([]);
            // setRecommendationsDataDiversion([]);
          });
      }
    }
  }, [props.project_id]);

  // useEffect(() => {
  //   if (selectedPhase) {
  //     // const filteredTasks = props.taskItem.Task.filter((task) => task.Status < 100 && task.SOW === selectedPhase);
  //     console.log('tasks', props.taskItem.);
  //     // setTasks(filteredTasks);
  //   }
  // }, [selectedPhase]);
  useEffect(() => {
    if (selectedPhase[index] && selectedPhase[index][altIndex]) {
      // Find the phase object that matches the selected phase
      const phase = props.taskItem.find((item) => item.Phase === selectedPhase[index][altIndex]);

      if (phase && phase.Task) {
        // Filter the tasks under the selected phase
        const filteredTasks = phase.Task.filter((task) => task.Status < 100);
        setTasks(filteredTasks);
      }
    }
  }, [selectedPhase]);

  // Set original data when recommendationsDataDiversion changes
  useEffect(() => {
    setOriginalDataDiversion(recommendationsDataDiversion);
  }, [recommendationsDataDiversion]);

  useEffect(() => {
    if (features.length > 0) {
      setIsCatchUpRecoAllowed(findAllowedFeature(features, 11));
    }
  }, [features]);

  {
    /**
    MODULES --> rdx_module
    - project-overview
    - project-estimate
    - project-scope-of-work
    - project-materials
    - project-equipment
    - project-team
    - masterlist-manpower
    - masterlist-equipment
    **/
  }

  const ShowIssue = () => {
    const notif = new Notifications();
    // const company_id = redux_notifications.company_id;
    // const project_id = redux_notifications.project_id;
    const limit = showMore == 1 ? -1 : 5;

    // if (rdx_module == "project-materials") {
    //   console.log(redux_notifications);
    //   notif.request_material_notifications(company_id, project_id, limit);
    // } else if (rdx_module == "project-scope-of-work") {
    //   notif.request_sow_notifications(company_id, project_id, limit);
    // }

    // if (redux_notifications.list.length > 5) {
    //   setShowMore(0);
    // } else {
    //   setShowMore(1);
    // }

    // setShowMore(showMore == 1 ? 0 : 1);
  };

  const notifications = issue.map((item, Index) => {
    // let color = "aimhi-notif";
    // if (item.threshold >= 50 && item.threshold < 75) {
    //   color = "green-notif";
    // } else if (item.threshold >= 75 && item.threshold < 90) {
    //   color = "yellow-notif";
    // } else if (item.threshold >= 90 && item.threshold < 101) {
    //   color = "orange-notif";
    // } else if (item.threshold > 100) {
    //   color = "red-notif";
    // } else {
    //   color = "aimhi-notif";
    // }

    let color = item.severity + '-notif';
    console.log(item.severity, 'severity');
    // let color = "aimhi";
    // if (item.threshold >= 50 && item.threshold < 75) {
    //   color = "green";
    // } else if (item.threshold >= 75 && item.threshold < 90) {
    //   color = "yellow";
    // } else if (item.threshold >= 90 && item.threshold < 101) {
    //   color = "orange";
    // } else if (item.threshold > 100) {
    //   color = "red";
    // } else {
    //   color = "aimhi";
    // }

    return (
      <CListGroupItem key={Index}>
        <CRow>
          <CCol xm="12" sm="12" md="9" lg="9">
            <span

            // style={{ fo }}
            >
              {item.issue}
            </span>

            <div className="list-font-style">{/* <span>Get your activities in line with this update</span> */}</div>
          </CCol>
          <CCol xm="12" sm="12" md="3" lg="3">
            <FaExclamationTriangle style={{ width: '30px', height: '30px', marginTop: '10px' }} className={color} />
          </CCol>
        </CRow>
      </CListGroupItem>
    );
  });

  {
    /** HIDDEN FUNCTIONS - Recommended next action*/
  }
  // const handleOptionChange = (recommendationIndex, optionIndex, option) => {
  //   setSelectedOptions((prevSelectedOptions) => ({
  //     ...prevSelectedOptions,
  //     [recommendationIndex]: optionIndex,
  //   }));
  //   console.log('optionIndex handleOptionChange', optionIndex);
  //   console.log('option handleOptionChange', option);

  //   const data = option.actual_cost.map((item) => ({
  //     0: item.sow,
  //     labor: item.labor.map((laborItem) => ({
  //       // Adjust labor mapping
  //       calculator: 0,
  //       edit: true,
  //       is_auto_compute: false,
  //       is_sub_con: false,
  //       is_variance_order: false,
  //       designation: laborItem.designation, // Include designation
  //       duration: laborItem.duration,
  //       estimated_workers: laborItem.estimated_workers,
  //       labor_id: laborItem.labor_id,
  //       rate: laborItem.labor_rate,
  //       id: laborItem.project_labor_id,
  //       hired_workers: Math.ceil(laborItem.hired_workers + laborItem.labor_count),
  //       // labor_count: laborItem.labor_count, // Include labor_count
  //     })),
  //     equipment: item.equipment.map((equipmentItem) => ({
  //       // Adjust equipment mapping
  //       actual_cost: 0,
  //       calculator: 0,
  //       description: equipmentItem.designation, // Include name
  //       duration: equipmentItem.duration,
  //       edit: true,
  //       estimated_quantity: equipmentItem.estimated_quantity,
  //       is_auto_compute: false,
  //       is_sub_con: false,
  //       is_variance_order: false,
  //       equipment_category_id: equipmentItem.equipment_id,
  //       rate: equipmentItem.equipment_rate,
  //       id: equipmentItem.project_equipment_id,
  //       ordered_quantity: Math.ceil(equipmentItem.ordered_quantity + equipmentItem.equipment_count),
  //       equipment_count: equipmentItem.equipment_count, // Include equipment_count
  //     })),
  //     material: [],
  //     phase: item.phase,
  //     phase_id: item.phase_id,
  //     edit: true,
  //     sow_id: item.sow_id,
  //     psow_id: item.psow_id,
  //     total_actual_labor_cost: 0,
  //     total_actual_equipment_cost: 0,
  //     quantity: 0,
  //     measurement: item.measurement,
  //     unit_id: item.unit_id,
  //   }));

  //   // const getPsowId = (sowName, schedule) => {
  //   //   console.log('schedule getPsowId', schedule);
  //   //   console.log('sowName getPsowId', sowName);
  //   //   let psowId = null;
  //   //   schedule.forEach((project) => {
  //   //     project.sow.forEach((task) => {
  //   //       if (task.name === sowName) {
  //   //         console.log('sowName', sowName);
  //   //         psowId = task.id;
  //   //       }
  //   //     });
  //   //   });
  //   //   return psowId;
  //   // };

  //   // // Assign psow_id to each data option
  //   // data.forEach((item) => {
  //   //   item.psow_id = getPsowId(item[0], option.schedule);
  //   // });

  //   console.log('data handleOptionChange', data);
  //   setCatchupData(data);
  //   props.onOptionSelected(optionIndex, option.schedule);
  // };

  const handleOptionChange = (recommendationIndex, optionIndex, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [recommendationIndex]: optionIndex,
    }));

    const data = option.actual_cost
      .map((item) => {
        // Check if both labor and equipment arrays are empty or contain null counts
        if ((item.labor.length === 0 || item.labor.every((laborItem) => laborItem.labor_count === null)) && (item.equipment.length === 0 || item.equipment.every((equipmentItem) => equipmentItem.equipment_count === null))) {
          return null; // Skip this item if both labor and equipment arrays are empty or contain null counts
        }

        const mappedItem = {
          0: item.sow,
          labor: [],
          equipment: [],
          material: [],
          phase: item.phase,
          phase_id: item.phase_id,
          edit: true,
          sow_id: item.sow_id,
          psow_id: item.psow_id,
          total_actual_labor_cost: 0,
          total_actual_equipment_cost: 0,
          quantity: 0,
          measurement: item.measurement,
          unit_id: item.unit_id,
        };

        // Add labor if not empty
        if (item.labor.length > 0) {
          const laborItems = item.labor
            .filter((laborItem) => laborItem.labor_count !== null) // Filter out labor items with null labor_count
            .map((laborItem) => ({
              calculator: 0,
              edit: true,
              is_auto_compute: false,
              is_sub_con: false,
              is_variance_order: false,
              designation: laborItem.designation,
              duration: laborItem.duration.toString(),
              estimated_workers: laborItem.estimated_workers,
              labor_id: laborItem.labor_id,
              rate: laborItem.labor_rate,
              id: laborItem.project_labor_id,
              hired_workers: Math.ceil(laborItem.hired_workers + laborItem.labor_count),
            }));

          mappedItem.labor.push(...laborItems);
        }

        // Add equipment if not empty
        if (item.equipment.length > 0) {
          const equipmentItems = item.equipment.map((equipmentItem) => ({
            actual_cost: 0,
            calculator: 0,
            description: equipmentItem.designation,
            duration: equipmentItem.duration.toString(),
            edit: true,
            estimated_quantity: equipmentItem.estimated_quantity,
            is_auto_compute: false,
            is_sub_con: false,
            is_variance_order: false,
            equipment_category_id: equipmentItem.equipment_id,
            rate: equipmentItem.equipment_rate,
            id: equipmentItem.project_equipment_id,
            ordered_quantity: Math.ceil(equipmentItem.ordered_quantity + equipmentItem.equipment_count),
            equipment_count: equipmentItem.equipment_count,
          }));
          mappedItem.equipment.push(...equipmentItems);
        }

        // If both labor and equipment arrays are empty or contain null counts, return null
        if (!mappedItem.labor && !mappedItem.equipment) {
          return null;
        }

        return mappedItem;
      })
      .filter((item) => item !== null);

    setCatchupData(data);
    props.onOptionSelected(optionIndex, option.schedule);
  };

  const handleOptionChangeWeather = (recommendationIndex, optionIndex, option) => {
    setSelectedOptionsWeather((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [recommendationIndex]: optionIndex,
    }));

    const data = option.actual_cost
      .map((item) => {
        // Check if both labor and equipment arrays are empty or contain null counts
        if ((item.labor.length === 0 || item.labor.every((laborItem) => laborItem.labor_count === null)) && (item.equipment.length === 0 || item.equipment.every((equipmentItem) => equipmentItem.equipment_count === null))) {
          return null; // Skip this item if both labor and equipment arrays are empty or contain null counts
        }

        const mappedItem = {
          0: item.sow,
          labor: [],
          equipment: [],
          material: [],
          phase: item.phase,
          phase_id: item.phase_id,
          edit: true,
          sow_id: item.sow_id,
          psow_id: item.psow_id,
          total_actual_labor_cost: 0,
          total_actual_equipment_cost: 0,
          quantity: 0,
          measurement: item.measurement,
          unit_id: item.unit_id,
        };

        // Add labor if not empty
        if (item.labor.length > 0) {
          const laborItems = item.labor
            .filter((laborItem) => laborItem.labor_count !== null) // Filter out labor items with null labor_count
            .map((laborItem) => ({
              calculator: 0,
              edit: true,
              is_auto_compute: false,
              is_sub_con: false,
              is_variance_order: false,
              designation: laborItem.designation,
              duration: laborItem.duration.toString(),
              estimated_workers: laborItem.estimated_workers,
              labor_id: laborItem.labor_id,
              rate: laborItem.labor_rate,
              id: laborItem.project_labor_id,
              hired_workers: Math.ceil(laborItem.hired_workers + laborItem.labor_count),
            }));

          mappedItem.labor.push(...laborItems);
        }

        // Add equipment if not empty
        if (item.equipment.length > 0) {
          const equipmentItems = item.equipment.map((equipmentItem) => ({
            actual_cost: 0,
            calculator: 0,
            description: equipmentItem.designation,
            duration: equipmentItem.duration.toString(),
            edit: true,
            estimated_quantity: equipmentItem.estimated_quantity,
            is_auto_compute: false,
            is_sub_con: false,
            is_variance_order: false,
            equipment_category_id: equipmentItem.equipment_id,
            rate: equipmentItem.equipment_rate,
            id: equipmentItem.project_equipment_id,
            ordered_quantity: Math.ceil(equipmentItem.ordered_quantity + equipmentItem.equipment_count),
            equipment_count: equipmentItem.equipment_count,
          }));
          mappedItem.equipment.push(...equipmentItems);
        }

        // If both labor and equipment arrays are empty or contain null counts, return null
        if (!mappedItem.labor && !mappedItem.equipment) {
          return null;
        }

        return mappedItem;
      })
      .filter((item) => item !== null);

    setCatchupData(data);
    props.onOptionSelected(optionIndex, option.schedule);
  };

  const isAcceptButtonDisabled = (index) => {
    return selectedOptions[index] === undefined;
  };

  const isAcceptButtonWeatherDisabled = (index) => {
    return selectedOptionsWeather[index] === undefined;
  };

  const handleFormSubmit = (event, recommendationIndex) => {
    event.preventDefault();
    const selectedOption = selectedOptions[recommendationIndex];
    // Add logic to handle the submission of the selected option
    console.log(`Recommendation ${recommendationIndex + 1} - Selected option:`, selectedOption);
  };
  const handleToggleRecommendations = (index) => {
    setExpandedIndexRecommendations((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleToggleRecommendationsWeather = (index) => {
    setExpandedIndexRecommendationsWeather((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleToggleDependency = (index) => {
    setExpandedIndexDependency((prevIndex) => (prevIndex === index ? null : index));
    props.show_all('all');
  };

  const handleToggleDiversion = (index) => {
    setExpandedIndexDiversion((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleToggleCatchup = (index) => {
    setExpandedIndexCatchup((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleToggleCatchupWeather = (index) => {
    setExpandedIndexCatchupWeather((prevIndex) => (prevIndex === index ? null : index));
  };

  const removeDataDependency = (index) => {
    const updatedRecommendations = [...recommendationsDataDependency];
    updatedRecommendations.splice(index, 1);
    setRecommendationsDataDependency(updatedRecommendations);
  };

  const removeDataCatchup = (index) => {
    const updatedRecommendations = [...recommendationsDataCatchUp];
    updatedRecommendations.splice(index, 1);
    setRecommendationsDataCatchup(updatedRecommendations);
    setRecommendationsData([]);
    setExpandedIndexCatchup(null);
    setSelectedOptions({});
    setCatchupPlanAvailable(false);
  };

  const removeDataCatchupWeather = (index) => {
    const updatedRecommendations = [...recommendationsDataCatchUpWeather];
    updatedRecommendations.splice(index, 1);
    setRecommendationsDataCatchUpWeather(updatedRecommendations);
    setRecommendationsWeatherData([]);
    setExpandedIndexCatchupWeather(null);
    setSelectedOptionsWeather({});
    setCatchupPlanAvailableWeather(false);
  };

  const removeDataDiversion = () => {
    setRecommendationsDataDiversion([]);
  };

  const handleDecline = (index) => {
    Swal.fire({
      title: 'Warning',
      text: 'By declining, you choose not to set up the dependencies based on the recommendation. Are you sure you want to decline? Note that it will remove all the dependencies that were set up.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn confirm',
        cancelButton: 'btn cancel',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        $axios
          .post('recommender/status/update', { project_id: props.project_id, status: 3, column: 'uc_6' })
          .then((res) => {
            if (res.status === 200) {
              console.log('res');
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong. Please try again.',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn confirm',
                cancelButton: 'btn cancel',
              },
              buttonsStyling: false,
            });
          });
        removeDataDependency(index);
        props.onDecline();
        Swal.fire({
          title: 'Success',
          text: 'Dependencies have been successfully removed.',
          icon: 'success',
          confirmButtonText: 'Confirm',
          customClass: {
            confirmButton: 'btn confirm',
            cancelButton: 'btn cancel',
          },
          buttonsStyling: false,
        });
      }
    });
  };

  const goToSchedule = () => {
    history.push(`/schedule/Schedule?id=${props.project_id}`);
  };

  const goToScheduleEditableCatchup = (slippage) => {
    const newPath = `/schedule/Schedule?id=${props.project_id}&catchup=true&slippage=${slippage}`;
    if (location.pathname + location.search !== newPath) {
      history.push(newPath);
    }
  };

  const handleAcceptDiversion = () => {
    // Find all items without a checked alternate and tag them
    const itemsWithoutAlternate = recommendationsDataDiversion.map((item) => {
      if (item.alternate.some((alternate) => alternate.isChecked)) {
        return { ...item, no_alternate: false };
      } else {
        return { ...item, no_alternate: true };
      }
    });

    // Update the state
    setRecommendationsDataDiversion(itemsWithoutAlternate);
    const itemWithoutAlternate = recommendationsDataDiversion.find((item) => !item.alternate.some((alternate) => alternate.isChecked));

    // If such an item exists, scroll to it
    if (itemWithoutAlternate) {
      const element = document.getElementById(`item-${itemWithoutAlternate.psow_id}-${recommendationsDataDiversion.indexOf(itemWithoutAlternate)}`);
      // itemWithoutAlternate.no_alternate = true;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    } else {
      let extractedData = [];
      // Only include checked items in the new recommendations
      const newRecommendations = recommendationsDataDiversion.map((item) => ({
        ...item,
        alternate: item.alternate.filter((alternateItem) => alternateItem.isChecked),
      }));

      newRecommendations.forEach((item) => {
        if (item.alternate.length > 0) {
          const mainData = {
            psow_id: item.psow_id,
            start_date: item.new_start,
            end_date: item.new_end,
          };

          extractedData.push(mainData);
        } else {
          item.no_alternate = true;
        }

        // Extract the required properties from each object in the dependencies array (if it exists)
        if (item.dependencies) {
          if (item.alternate.length > 0) {
            item.dependencies.forEach((dep) => {
              const depData = {
                psow_id: dep.psow_id,
                start_date: dep.new_start,
                end_date: dep.new_end,
              };

              extractedData.push(depData);
            });
          } else {
            item.no_alternate = true;
          }
        }

        // Extract the required properties from each object in the alternate array (if it exists)
        if (item.alternate) {
          item.alternate.forEach((alt) => {
            if (alt.isChecked) {
              const altData = {
                psow_id: alt.psow_id,
                start_date: alt.new_start,
                end_date: alt.new_end,
              };

              extractedData.push(altData);
            } else {
              item.no_alternate = true;
            }
          });
        }
      });
      Swal.fire({
        title: 'Warning',
        text: 'By accepting, the diversion plan it will apply all the changes automatically. Are you sure you want to accept? ',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await $axios
            .post('recommender/save', extractedData)
            .then((res) => {
              if (res.data.status === 200) {
                $axios
                  .post('recommender/status/update', { project_id: props.project_id, status: 2, column: 'uc_7' })
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('res');
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      text: 'Something went wrong. Please try again.',
                      icon: 'error',
                      showCancelButton: true,
                      confirmButtonText: 'Confirm',
                      cancelButtonText: 'Cancel',
                      customClass: {
                        confirmButton: 'btn confirm',
                        cancelButton: 'btn cancel',
                      },
                      buttonsStyling: false,
                    });
                  });
                removeDataDiversion();
                setModal(!modal);
                Swal.fire({
                  title: 'Success',
                  text: 'Diversion plan applied succesfully. Do you wish to view the Schedule ?',
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonText: 'Confirm',
                  cancelButtonText: 'Cancel',
                  customClass: {
                    confirmButton: 'btn confirm',
                    cancelButton: 'btn cancel',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    goToSchedule();
                  }
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.data.response,
                  text: res.data.description,
                  confirmButtonColor: '#23303D',
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        }
      });
    }
  };

  const handleDeclineDiversion = () => {
    Swal.fire({
      title: 'Warning',
      text: 'Dismissing the diversion plan will discard all proposed changes. Are you sure you want to dismiss?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn confirm',
        cancelButton: 'btn cancel',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeDataDiversion();
        setModal(false);
        $axios
          .post('recommender/status/update', { project_id: props.project_id, status: 3, column: 'uc_7' })
          .then((res) => {
            if (res.status === 200) {
              console.log('res');
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong. Please try again.',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn confirm',
                cancelButton: 'btn cancel',
              },
              buttonsStyling: false,
            });
          });
      }
    });
  };
  {
    /** HIDDEN FUNCTIONS - Recommended next action*/
  }

  const handleAcceptDependency = (index) => {
    // props.acceptDiversion();
    Swal.fire({
      title: 'Warning',
      text: 'By accepting, you agree to set up the dependencies based on the recommendation. Are you sure you want to accept?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn confirm',
        cancelButton: 'btn cancel',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        $axios
          // status 2 = accept, 3 = decline
          .post('recommender/status/update', { project_id: props.project_id, status: 2, column: 'uc_6' })
          .then(async (res) => {
            if (res.status === 200) {
              await props.onAccept();
              Swal.fire({
                title: 'Success',
                text: 'Dependencies have been set up successfully.',
                icon: 'success',
                confirmButtonText: 'Confirm',
                customClass: {
                  confirmButton: 'btn confirm',
                  cancelButton: 'btn cancel',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  removeDataDependency(index);
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong. Please try again.',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn confirm',
                cancelButton: 'btn cancel',
              },
              buttonsStyling: false,
            });
          });
      }
    });
  };

  const handleAcceptCatchup = (index, uc) => {
    // props.acceptDiversion();
    Swal.fire({
      title: 'Warning',
      text: 'By accepting the catch-up plan, it will apply all the changes automatically. Are you sure you want to accept? ',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn confirm',
        cancelButton: 'btn cancel',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (props.scheduleData.length > 0) {
          await $axios
            .post('recommender/save', props.scheduleData)
            .then((res) => {
              if (res.data.status === 200) {
                console.log('res');
                axios
                  .post(IP + 'estimate/save', {
                    project_id: props.project_id, //need to make this dynamic
                    estimate_data: catchUpData,
                    estimate_status: 2,
                    company_id: company_id, //need to make this dynamic
                    user_id: user_id, //need to make this dynamic
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${accessToken}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.status == 1) {
                      $axios
                        // status 2 = accept, 3 = decline
                        .post('recommender/status/update', { project_id: props.project_id, status: 2, column: uc === 5 ? 'uc_5' : 'uc_1' })
                        .then((res) => {
                          if (res.status === 200) {
                            props.getdata(props.project_id);
                            // props.onShowBaseline();
                            if (uc === 5) {
                              removeDataCatchup(index);
                            } else {
                              removeDataCatchupWeather(index);
                            }
                            Swal.fire({
                              title: 'Success',
                              text: 'Catch-up plan applied succesfully.',
                              icon: 'success',
                              showCancelButton: true,
                              confirmButtonText: 'Confirm',
                              cancelButtonText: 'Cancel',
                              customClass: {
                                confirmButton: 'btn confirm',
                                cancelButton: 'btn cancel',
                              },
                              buttonsStyling: false,
                            });
                          }
                        })
                        .catch((error) => {
                          Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong. Please try again.',
                            icon: 'error',
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            cancelButtonText: 'Cancel',
                            customClass: {
                              confirmButton: 'btn confirm',
                              cancelButton: 'btn cancel',
                            },
                            buttonsStyling: false,
                          });
                        });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: res.data.response,
                        text: res.data.description,
                        confirmButtonColor: '#23303D',
                      });
                    }
                  })
                  .catch((error) => {
                    console.log('error', error);
                  });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.data.response,
                  text: res.data.description,
                  confirmButtonColor: '#23303D',
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        } else {
          axios
            .post(IP + 'estimate/save', {
              project_id: props.project_id, //need to make this dynamic
              estimate_data: catchUpData,
              estimate_status: 2,
              company_id: company_id, //need to make this dynamic
              user_id: user_id, //need to make this dynamic
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((res) => {
              if (res.data.status == 1) {
                $axios
                  // status 2 = accept, 3 = decline
                  .post('recommender/status/update', { project_id: props.project_id, status: 2, column: uc === 5 ? 'uc_5' : 'uc_1' })
                  .then((res) => {
                    if (res.status === 200) {
                      // props.onShowBaseline();
                      props.getdata(props.project_id);
                      if (uc === 5) {
                        removeDataCatchup(index);
                      } else {
                        removeDataCatchupWeather(index);
                      }
                      Swal.fire({
                        title: 'Success',
                        text: 'Catch-up plan applied succesfully.',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        customClass: {
                          confirmButton: 'btn confirm',
                          cancelButton: 'btn cancel',
                        },
                        buttonsStyling: false,
                      });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: 'Error',
                      text: 'Something went wrong. Please try again.',
                      icon: 'error',
                      showCancelButton: true,
                      confirmButtonText: 'Confirm',
                      cancelButtonText: 'Cancel',
                      customClass: {
                        confirmButton: 'btn confirm',
                        cancelButton: 'btn cancel',
                      },
                      buttonsStyling: false,
                    });
                  });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.data.response,
                  text: res.data.description,
                  confirmButtonColor: '#23303D',
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        }
      }
    });
  };

  const handleDeclineCatchup = (index, uc) => {
    // props.acceptDiversion();
    Swal.fire({
      title: 'Warning',
      text: 'Declining the catch-up plan will discard all proposed changes. Are you sure you want to decline?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn confirm',
        cancelButton: 'btn cancel',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (uc === 5) {
          removeDataCatchup(index);
        } else {
          removeDataCatchupWeather(index);
        }
        $axios
          .post('recommender/status/update', { project_id: props.project_id, status: 3, column: uc === 5 ? 'uc_5' : 'uc_1' })
          .then((res) => {
            if (res.status === 200) {
              console.log('res');
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong. Please try again.',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn confirm',
                cancelButton: 'btn cancel',
              },
              buttonsStyling: false,
            });
          });
      }
    });
  };
  // const recommendations = recommendationsData.map((item, index) => {
  //   const color = item.severity + '-notif';
  //   const isExpanded = expandedIndex === index;

  //   return (
  //     <div key={index}>
  //       <CListGroupItem onClick={() => handleToggle(index)}>
  //         <CRow>
  //           <CCol xm="12" sm="12" md="9" lg="9">
  //             <span>{item.issue}</span>
  //             <div className="list-font-style">{/* Additional content for recommendations */}</div>
  //           </CCol>
  //           <CCol xm="12" sm="12" md="3" lg="3">
  //             <img src={ai} alt="AI Logo" style={{ marginRight: '8px', height: '36px', width: '36px' }} />
  //           </CCol>
  //         </CRow>
  //       </CListGroupItem>
  //       {isExpanded && (
  //         <CListGroupItem>
  //           <CForm onSubmit={(e) => handleFormSubmit(e, index)}>
  //             <CFormGroup>
  //               {item.options.map((option, optionIndex) => (
  //                 <CFormGroup variant="checkbox" key={optionIndex}>
  //                   <CInputRadio id={`option-${index}-${optionIndex}`} name={`option-${index}`} value={option} onChange={() => handleOptionChange(index, option)} checked={selectedOptions[index] === option} />
  //                   <CLabel htmlFor={`option-${index}-${optionIndex}`}>{option}</CLabel>
  //                 </CFormGroup>
  //               ))}
  //             </CFormGroup>
  //             <CButton type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', justifyContent: 'flex-end', display: 'flex' }}>
  //               Resolve
  //             </CButton>
  //             <CButton type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', justifyContent: 'flex-end', display: 'flex' }}>
  //               Resolve
  //             </CButton>
  //           </CForm>
  //         </CListGroupItem>
  //       )}
  //     </div>
  //   );
  // });
  // not used

  {
    /** HIDDEN FUNCTIONS - Recommended next action*/
  }
  const recommendations = recommendationsData.map((item, index) => {
    const color = item.severity + '-notif';
    const isExpanded = expandedIndexRecommendations === index;

    return (
      <div key={index}>
        <CListGroupItem onClick={() => handleToggleRecommendations(index)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <span>{item.issue}</span>
              <div className="list-font-style"></div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>
        {isExpanded && (
          <CListGroupItem>
            <CForm onSubmit={(e) => handleFormSubmit(e, index)}>
              <CFormGroup>
                {item?.options?.map((option, optionIndex) => (
                  <CFormGroup variant="checkbox" key={optionIndex}>
                    <CInputRadio id={`option-${index}-${optionIndex}`} name={`option-${index}`} value={option} onChange={() => handleOptionChange(index, optionIndex)} checked={selectedOptions[index] === optionIndex} />
                    <CLabel htmlFor={`option-${index}-${optionIndex}`}>{option}</CLabel>
                  </CFormGroup>
                ))}
              </CFormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CButton type="submit" onClick={goToSchedule} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
                  Review
                </CButton>
                <CButton type="submit" onClick={() => handleDeclineCatchup(index, 5)} style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                  Dismiss
                </CButton>
              </div>
            </CForm>
          </CListGroupItem>
        )}
      </div>
    );
  });

  const recommendationsForWeather = recommendationsWeatherData.map((item, index) => {
    const color = item.severity + '-notif';
    const isExpanded = expandedIndexRecommendationsWeather === index;

    return (
      <div key={`weather-${index}`}>
        <CListGroupItem onClick={() => handleToggleRecommendationsWeather(index)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <span>{item.issue}</span>
              <div className="list-font-style"></div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>
        {isExpanded && (
          <CListGroupItem>
            <CForm onSubmit={(e) => handleFormSubmit(e, index)}>
              <CFormGroup>
                {item?.options?.map((option, optionIndex) => (
                  <CFormGroup variant="checkbox" key={optionIndex}>
                    <CInputRadio id={`option-${index}-${optionIndex}`} name={`option-${index}`} value={option} onChange={() => handleOptionChange(index, optionIndex)} checked={selectedOptions[index] === optionIndex} />
                    <CLabel htmlFor={`option-${index}-${optionIndex}`}>{option}</CLabel>
                  </CFormGroup>
                ))}
              </CFormGroup>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CButton type="submit" onClick={goToSchedule} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
                  Review
                </CButton>
                <CButton type="submit" onClick={() => handleDeclineCatchup(index, 1)} style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                  Dismiss
                </CButton>
              </div>
            </CForm>
          </CListGroupItem>
        )}
      </div>
    );
  });

  const recommendationOnDependency = recommendationsDataDependency.map((item, index) => {
    const color = item.severity + '-notif';
    const isExpanded = expandedIndexDependency === index;

    return (
      <div key={index}>
        <CListGroupItem onClick={() => handleToggleDependency(index)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <span>{item.issue}</span>
              <div className="list-font-style"></div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>
        {isExpanded && (
          <CListGroupItem>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CCol xm="12" sm="12" md="9" lg="9">
                <CRow style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CButton onClick={() => handleAcceptDependency(index)} type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
                    Accept
                  </CButton>
                  <CButton
                    type="submit"
                    style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}
                    onClick={() => {
                      handleDecline(index);
                    }}
                  >
                    Decline
                  </CButton>
                </CRow>
              </CCol>
            </div>
          </CListGroupItem>
        )}
      </div>
    );
  });

  const highlightKeywords = (label) => {
    // Define an array of keywords you want to highlight
    const keywords = ['Php \\d+(?:,\\d+)*\\.\\d+', '\\d+ days']; // Regular expressions for "Php <numbers>" and "<numbers> days"
    // Create a regular expression pattern to match any of the keywords
    const pattern = new RegExp(keywords.join('|'), 'g');
    // Replace matched keywords with bolded versions
    return label.replace(pattern, '<strong>$&</strong>');
  };

  const recommendationsCatchUp = recommendationsDataCatchUp
    ?.map((item) => ({
      ...item,
      options: item.options
        .map((option) => ({
          ...option,
          actual_cost: option.actual_cost.filter((cost) => cost.labor.length > 0 || cost.equipment.length > 0),
        }))
        .filter((option) => option.actual_cost.length > 0),
    }))
    ?.filter((item) => item.options.length > 0)
    ?.map((item, index) => {
      const color = item.severity + '-notif';
      const isExpanded = expandedIndexCatchup === index;

      return (
        <div key={index}>
          <CListGroupItem onClick={() => handleToggleCatchup(index)}>
            <CRow>
              <CCol xm="12" sm="12" md="9" lg="9">
                <span>{item.recommendation}</span>
                <div className="list-font-style"></div>
              </CCol>
              <CCol xm="12" sm="12" md="3" lg="3">
                <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
              </CCol>
            </CRow>
          </CListGroupItem>
          {isExpanded && (
            <CListGroupItem>
              <CForm onSubmit={(e) => handleFormSubmit(e, index)}>
                <CFormGroup style={{ maxHeight: '400px', overflow: 'auto' }}>
                  {item?.options?.map((option, optionIndex) => (
                    <CFormGroup variant="checkbox" key={optionIndex}>
                      <CInputRadio id={`option-${index}-${optionIndex}`} name={`option-${index}`} value={option} onChange={() => handleOptionChange(index, optionIndex, option)} checked={selectedOptions[index] === optionIndex} />
                      {/* <CLabel htmlFor={`option-${index}-${optionIndex}`}>{option.label}</CLabel> */}
                      <CLabel htmlFor={`option-${index}-${optionIndex}`} dangerouslySetInnerHTML={{ __html: highlightKeywords(option.label) }} />

                      {selectedOptions[index] === optionIndex && option.actual_cost && option.actual_cost.length > 0 && (
                        // Check if the option is selected and showCallout is true
                        <CCallout color="danger" className={'bg-white'}>
                          <div className="text-muted">Choosing this option will add the following:</div>
                          {option.actual_cost?.map((data, index) => {
                            return (
                              <ul key={index} style={{ paddingLeft: '20px' }}>
                                <li>{`Scope of work: ${data.phase},  ${data.sow}`}</li>
                                <ul style={{ paddingLeft: '20px' }}>
                                  {data.labor?.map((labor, laborIndex) => (
                                    <li key={laborIndex}>{`${Math.ceil(labor.labor_count)} ${labor.designation}`}</li>
                                  ))}
                                  {data.equipment?.map((equipment, equipmentIndex) => (
                                    <li key={equipmentIndex}>{`${Math.ceil(equipment.equipment_count)} ${equipment.designation}`}</li>
                                  ))}
                                  {/* <li>{`${data?.labor_count} ${data?.labor}`}</li> */}
                                  {/* <li>{`${data?.equipment_count} ${data?.equipment}`}</li> */}
                                </ul>
                              </ul>
                            );
                          })}

                          <br />
                        </CCallout>
                      )}
                    </CFormGroup>
                  ))}
                </CFormGroup>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CButton type="submit" onClick={() => handleAcceptCatchup(index, 5)} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }} disabled={isAcceptButtonDisabled(index)}>
                    Accept
                  </CButton>
                  <CButton type="submit" onClick={() => handleDeclineCatchup(index, 5)} style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                    Dismiss
                  </CButton>
                </div>
              </CForm>
            </CListGroupItem>
          )}
        </div>
      );
    });

  const recommendationsCatchUpWeather = recommendationsDataCatchUpWeather
    ?.map((item) => ({
      ...item,
      options: item.options
        .map((option) => ({
          ...option,
          actual_cost: option.actual_cost.filter((cost) => cost.labor.length > 0 || cost.equipment.length > 0),
        }))
        .filter((option) => option.actual_cost.length > 0),
    }))
    ?.filter((item) => item.options.length > 0)
    ?.map((item, index) => {
      const color = item.severity + '-notif';
      const isExpanded = expandedIndexCatchupWeather === index;

      return (
        <div key={`catch-up-weather-${index}`}>
          <CListGroupItem onClick={() => handleToggleCatchupWeather(index)}>
            <CRow>
              <CCol xm="12" sm="12" md="9" lg="9">
                <span>{item.recommendation}</span>
                <div className="list-font-style"></div>
              </CCol>
              <CCol xm="12" sm="12" md="3" lg="3">
                <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
              </CCol>
            </CRow>
          </CListGroupItem>
          {isExpanded && (
            <CListGroupItem>
              <CForm onSubmit={(e) => handleFormSubmit(e, index)}>
                <CFormGroup style={{ maxHeight: '400px', overflow: 'auto' }}>
                  {item?.options?.map((option, optionIndex) => (
                    <CFormGroup variant="checkbox" key={optionIndex}>
                      <CInputRadio id={`option-${index}-${optionIndex}`} name={`option-${index}`} value={option} onChange={() => handleOptionChangeWeather(index, optionIndex, option)} checked={selectedOptionsWeather[index] === optionIndex} />
                      {/* <CLabel htmlFor={`option-${index}-${optionIndex}`}>{option.label}</CLabel> */}
                      <CLabel htmlFor={`option-${index}-${optionIndex}`} dangerouslySetInnerHTML={{ __html: highlightKeywords(option.label) }} />

                      {selectedOptionsWeather[index] === optionIndex && option.actual_cost && option.actual_cost.length > 0 && (
                        // Check if the option is selected and showCallout is true
                        <CCallout color="danger" className={'bg-white'}>
                          <div className="text-muted">Choosing this option will add the following:</div>
                          {option.actual_cost?.map((data, index) => {
                            return (
                              <ul key={index} style={{ paddingLeft: '20px' }}>
                                <li>{`Scope of work: ${data.phase},  ${data.sow}`}</li>
                                <ul style={{ paddingLeft: '20px' }}>
                                  {data.labor?.map((labor, laborIndex) => (
                                    <li key={laborIndex}>{`${Math.ceil(labor.labor_count)} ${labor.designation}`}</li>
                                  ))}
                                  {data.equipment?.map((equipment, equipmentIndex) => (
                                    <li key={equipmentIndex}>{`${Math.ceil(equipment.equipment_count)} ${equipment.designation}`}</li>
                                  ))}
                                  {/* <li>{`${data?.labor_count} ${data?.labor}`}</li> */}
                                  {/* <li>{`${data?.equipment_count} ${data?.equipment}`}</li> */}
                                </ul>
                              </ul>
                            );
                          })}

                          <br />
                        </CCallout>
                      )}
                    </CFormGroup>
                  ))}
                </CFormGroup>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CButton type="submit" onClick={() => handleAcceptCatchup(index, 1)} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }} disabled={isAcceptButtonWeatherDisabled(index)}>
                    Accept
                  </CButton>
                  <CButton type="submit" onClick={() => handleDeclineCatchup(index, 1)} style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                    Dismiss
                  </CButton>
                </div>
              </CForm>
            </CListGroupItem>
          )}
        </div>
      );
    });

  // Diversion plan functions starts here

  // Clear all function
  const clearAll = (index) => {
    setRecommendationsDataDiversion(
      recommendationsDataDiversion.map((item, i) =>
        i === index
          ? {
              ...item,
              isCleared: true,
              alternate: [
                {
                  dependencies: [],
                  new_end: null,
                  new_start: null,
                  old_end: '',
                  old_start: '',
                  phase: '',
                  psow_id: '',
                  sow: '',
                },
              ],
            }
          : item,
      ),
    );
  };

  // Restore function
  const restore = () => {
    setRecommendationsDataDiversion(originalDataDiversion);
  };

  const deleteItem = (index) => {
    const newRecommendationsDataDiversion = recommendationsDataDiversion.filter((item, i) => i !== index);
    setRecommendationsDataDiversion(newRecommendationsDataDiversion);

    // Check if the last item is being deleted
    if (newRecommendationsDataDiversion.length === 0) {
      handleDeclineDiversion();
    }
  };
  const handleDateChange = (date, itemIndex, isAlternate, alternateIndex, dateType) => {
    const dateString = date.toISOString().slice(0, 10);
    setRecommendationsDataDiversion((prevState) => {
      const newState = [...prevState];
      if (isAlternate) {
        newState[itemIndex].alternate[alternateIndex][dateType] = dateString;
      } else {
        newState[itemIndex][dateType] = dateString;
      }
      return newState;
    });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return dateString ? new Date(dateString).toLocaleDateString(undefined, options) : 'Select work item';
  };

  // Function to render the date table for use case 7 diversion
  const renderDateTable = (old_start, new_start, old_end, new_end, is_alternate, itemIndex, alternateIndex) => (
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
      <thead>
        <tr>
          <th style={{ width: '33%', textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>Field</th>
          <th style={{ width: '33%', textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>Old Date</th>
          <th style={{ width: '33%', textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>New Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>Target Start</td>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px', color: 'red' }}>
            <div style={{ backgroundColor: '#B42A19', color: 'white', padding: '4px', borderRadius: '4px' }}>{formatDate(old_start)}</div>
          </td>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>
            <DatePicker key={new_start} className="date-picker datepicker-placeholder" placeholderText="mm/dd/yyyy" selected={new_start ? new Date(new_start) : null} onChange={(date) => handleDateChange(date, itemIndex, is_alternate, alternateIndex, 'new_start')} />
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>Target End</td>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px', color: 'red' }}>
            <div style={{ backgroundColor: '#B42A19', color: 'white', padding: '4px', borderRadius: '4px' }}>{formatDate(old_end)}</div>
          </td>
          <td style={{ textAlign: 'center', border: '1px solid #ddd', padding: '8px' }}>
            <DatePicker key={new_end} className="date-picker datepicker-placeholder" placeholderText="mm/dd/yyyy" selected={new_end ? new Date(new_end) : null} onChange={(date) => handleDateChange(date, itemIndex, is_alternate, alternateIndex, 'new_end')} />
          </td>
        </tr>
      </tbody>
    </table>
  );

  // Function to render dependencies for use case 7 diversion
  const renderDependencies = (dependencies, header) => (
    <>
      {dependencies.length > 0 && (
        <CCol style={{ marginLeft: '10px', marginTop: '10px' }}>
          {dependencies.length > 1 ? (
            <span>
              <strong>{header}</strong>
            </span>
          ) : (
            <span>
              <strong>{header}</strong>
            </span>
          )}
        </CCol>
      )}

      {dependencies.map((dependency, index) => (
        <React.Fragment key={index}>
          <CCol style={{ marginLeft: '10px', marginTop: '10px' }}>
            <span>{`Scope of Work: ${dependency.phase}`}</span>
          </CCol>
          <CCol style={{ marginLeft: '10px' }}>
            <span>{`Work Item: ${dependency.sow}`}</span>
          </CCol>
          <CCol style={{ marginLeft: '10px' }}>{renderDateTable(dependency.old_start, dependency.new_start, dependency.old_end, dependency.new_end, 0)}</CCol>
        </React.Fragment>
      ))}
    </>
  );

  const handleRemoveAlternate = (item, index) => {
    const newAlternate = [...item.alternate];
    newAlternate.splice(index, 1);
    const newItem = { ...item, alternate: newAlternate };
    setRecommendationsDataDiversion(recommendationsDataDiversion.map((rec) => (rec.psow_id === item.psow_id ? newItem : rec)));
  };

  const handleAddItem = (item, index) => {
    setRecommendationsDataDiversion((prevState) => {
      // Add the alternate item to the current item
      prevState[index].alternate.push({
        dependencies: [],
        new_end: null,
        new_start: null,
        old_end: '',
        old_start: '',
        phase: '',
        psow_id: '',
        sow: '',
      });

      // Return the updated state
      return [...prevState];
    });
    //add a null value to selectedPhases when a new item is added
    setSelectedPhase((prevState) => [...prevState, null]);

    // Wait for the state to update and the component to re-render
    setTimeout(() => {
      // Get the newly added item
      const newItem = document.getElementById(`alternate-item-${index}-${item.alternate.length - 1}`);

      // Scroll to the newly added item
      newItem.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const handleSelectAllChange = (e, index) => {
    const newRecommendations = [...recommendationsDataDiversion];
    newRecommendations[index].alternate.forEach((alt) => {
      alt.isChecked = e.target.checked;
    });
    setRecommendationsDataDiversion(newRecommendations);
  };

  const handleCheckboxChange = (e, index, altIndex) => {
    const newRecommendations = [...recommendationsDataDiversion];
    newRecommendations[index].alternate[altIndex].isChecked = e.target.checked;
    setRecommendationsDataDiversion(newRecommendations);
  };
  const handleSowChange = (phase, index, altIndex) => {
    setSelectedPhase((prevState) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];
      if (!newState[index]) {
        newState[index] = {};
      }
      newState[index][altIndex] = phase;
      return newState;
    });

    setSelectedWorkItem((prevState) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];
      if (!newState[index]) {
        newState[index] = [];
      }
      newState[index][altIndex] = null;
      return newState;
    });
    // set index of item
    setIndex(index);
    //set alternate index
    setAltIndex(altIndex);
  };

  const handleWorkItemChange = (work_item, index, altIndex) => {
    let selectedTaskItem;
    props.taskItem.forEach((taskItem) => {
      const foundTask = taskItem.Task.find((task) => task.id === work_item);
      if (foundTask) {
        selectedTaskItem = foundTask;
      }
    });
    // Set selectedWorkItem[index] to an object with value and label properties
    setSelectedWorkItem((prevState) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];
      if (!newState[index]) {
        newState[index] = [];
      }
      newState[index][altIndex] = { value: work_item, label: selectedTaskItem.SOW };
      return newState;
    });

    // Update recommendationsDataDiversion
    setRecommendationsDataDiversion((prevState) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];

      // Initialize a new object if it doesn't exist
      if (!newState[index].alternate[altIndex]) {
        newState[index].alternate[altIndex] = {};
      }
      newState[index].alternate[altIndex].old_start = selectedTaskItem.StartDate;
      newState[index].alternate[altIndex].old_end = selectedTaskItem.EndDate;
      newState[index].alternate[altIndex].psow_id = work_item;
      return newState;
    });
  };

  const renderRecommendationItem = (item, index) => {
    const isExpanded = expandedIndexDiversion === index;

    return (
      <div key={index}>
        <CListGroupItem onClick={() => handleToggleDiversion(index)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <span>
                There's a forecasted{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {item[0].weather} {`in ${item[0].days_rain} days time`}
                </span>
                , <span style={{ fontWeight: 'bold' }}>{item[0].phase}</span> , <span style={{ fontWeight: 'bold' }}>{item[0].sow} </span>
                {item.length - 1 > 0 && (
                  <>
                    and <span style={{ fontWeight: 'bold' }}>{item.length - 1} more</span>{' '}
                  </>
                )}
                is affected. Review diversion plan?
              </span>
              <div className="list-font-style"></div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>

        {isExpanded && (
          <CListGroupItem style={{ padding: '10px', maxHeight: '400px', overflow: 'auto', position: 'relative' }}>
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              {item?.map((item, index) => (
                // <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                <div>
                  <CCol>
                    <h6>
                      <strong>Original</strong>
                    </h6>
                  </CCol>
                  <CCol>
                    <span>{`Scope of Work: ${item.phase}`}</span>
                  </CCol>
                  <CCol>
                    <span>{` Work Item: ${item.sow}`}</span>
                  </CCol>

                  <CCol>{renderDateTable(item.old_start, item.new_start, item.old_end, item.new_end, '#B42A19', 0)}</CCol>

                  {item.dependencies.length > 0 && renderDependencies(item.dependencies, item.dependencies.length > 1 ? 'Dependent Tasks' : 'Dependent Task')}

                  {/* Render alternate section */}
                  {item.alternate.length > 0 && (
                    <>
                      <CCol style={{ marginTop: '10px' }}>
                        <h6>
                          <strong>Alternate</strong>
                        </h6>
                      </CCol>
                      {item.alternate.map((alternateItem, altIndex) => (
                        <React.Fragment key={altIndex}>
                          <CCol style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                            <CInput type="checkbox" style={{ width: '14px', height: '14px', marginRight: '10px' }} />
                            <span>{`Scope of Work: ${alternateItem.phase}`}</span>
                            <CButtonClose style={{ marginLeft: 'auto' }} onClick={() => handleRemoveAlternate(altIndex)} />
                          </CCol>
                          <CCol style={{ marginLeft: '24px' }}>
                            <span>{`Work Item: ${alternateItem.sow}`}</span>
                          </CCol>
                          <CCol>{renderDateTable(alternateItem.old_start, alternateItem.new_start, alternateItem.old_end, alternateItem.new_end, 1)}</CCol>

                          {/* Render dependencies in alternate section */}
                          {alternateItem.dependencies && renderDependencies(alternateItem.dependencies, alternateItem.dependencies.length > 1 ? 'Dependent Tasks' : 'Dependent Task')}
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  <div style={{ marginBottom: '20px' }}></div>
                </div>
              ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <CCol></CCol>
              <CCol xm="12" sm="12" md="9" lg="9">
                <CRow style={{ justifyContent: 'flex-end' }}>
                  <CButton
                    onClick={() => {
                      setModal(!modal);
                      setModalItem(index);
                    }}
                    type="submit"
                    style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}
                  >
                    Review
                  </CButton>
                  <CButton onClick={handleDeclineDiversion} type="submit" style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                    Dismiss
                  </CButton>
                </CRow>
              </CCol>
            </div>
          </CListGroupItem>
        )}
      </div>
    );
  };

  const renderRecommendationItemModal = (item, index) => {
    const sow_options = props.taskItem
      ?.filter((taskItem) => {
        return taskItem.Phase !== item.phase;
      })
      ?.map((item) => ({
        value: item.Phase,
        label: item.Phase,
      }));

    const work_item_options = tasks.map((task) => ({ value: task.id, label: task.SOW }));

    return (
      <div id={`item-${item.psow_id}-${index}`} style={{ maxHeight: '500px', overflow: 'auto', maxWidth: '100%', width: '100%', padding: '10px', marginTop: '20px' }}>
        <CCol xm="12" sm="12" md="12" lg="12" style={{ marginTop: '0px', marginBottom: '20px', paddingTop: '0px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <h5 style={{ width: '80vh' }}>
            <>
              <span style={{ fontWeight: 'bold' }}>{item.phase}</span>,{' '}
            </>
            <span style={{ fontWeight: 'bold' }}>{item.sow} </span>
            is affected with <span style={{ fontWeight: 'bold' }}>{item.weather}</span>.
          </h5>
          <MdOutlineClear size={28} style={{ cursor: 'pointer' }} onClick={() => deleteItem(index)} />
        </CCol>
        <CRow style={{ width: '98%', marginLeft: '10px' }}>
          <CCol>
            <h5 style={{ textAlign: 'center', position: 'sticky', top: '0', backgroundColor: '#fff', zIndex: 10, padding: '10px' }}>
              <strong>Original</strong>
            </h5>
            <CCol>
              <span>{`Scope of Work: ${item.phase}`}</span>
            </CCol>
            <CCol>
              <span>{` Work Item: ${item.sow}`}</span>
            </CCol>

            <CCol>{renderDateTable(item.old_start, item.new_start, item.old_end, item.new_end, '#B42A19', 0, index)}</CCol>

            {item.dependencies.length > 0 && renderDependencies(item.dependencies, item.dependencies.length > 1 ? 'Dependent Tasks' : 'Dependent Task')}
          </CCol>
          <CCol sm="1" md="1" lg="1" xl="1">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'sticky', top: '0', backgroundColor: '#fff', zIndex: 10 }}>
              <FaArrowRight size={24}></FaArrowRight>
            </div>
          </CCol>
          <CCol>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'sticky', top: '0', backgroundColor: '#fff', zIndex: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, paddingLeft: '15px' }}>
                <CInput type="checkbox" style={{ width: '14px', height: '14px', marginRight: '10px' }} onChange={(e) => handleSelectAllChange(e, index)} />
                <span>Select All</span>
              </div>
              <h5 style={{ textAlign: 'center', padding: '10px', margin: '0', flex: 1 }}>
                <strong>Alternate</strong>
              </h5>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <CTooltip content="Add item" placement="top">
                  <span>
                    <MdOutlinePostAdd size={24} style={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => handleAddItem(item, index)} />
                  </span>
                </CTooltip>
                <CTooltip content="Clear all" placement="top">
                  <span>
                    <MdOutlineClear size={24} style={{ cursor: 'pointer' }} onClick={() => clearAll(index)} />
                  </span>
                </CTooltip>
              </div>
            </div>
            {item.no_alternate && <CAlert color="aimhi-danger">Select atleast one alternate.</CAlert>}
            {item.alternate.map((alternateItem, altIndex) => (
              <React.Fragment>
                <CCol sm="12" md="12" lg="12" xl="12" style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }} id={`alternate-item-${index}-${altIndex}`}>
                  <CInput key={`checkbox-${altIndex}`} type="checkbox" style={{ width: '14px', height: '14px', marginRight: '10px', marginTop: '4px' }} checked={item.alternate[altIndex].isChecked} onChange={(e) => handleCheckboxChange(e, index, altIndex)} />
                  {alternateItem.phase === '' ? (
                    <div style={{ width: '100%' }}>
                      <span>Scope of Work: </span>
                      <Select size="sm" style={{ width: '500px' }} options={sow_options} onChange={(selectedOption) => handleSowChange(selectedOption.value, index, altIndex)}></Select>
                    </div>
                  ) : (
                    <span>{`Scope of Work: ${alternateItem.phase}`}</span>
                  )}
                  <CButtonClose style={{ marginLeft: 'auto' }} onClick={() => handleRemoveAlternate(item, altIndex)} />
                </CCol>
                <CCol style={{ marginLeft: '24px' }}>
                  {alternateItem.sow === '' ? (
                    <div style={{ width: '92%' }}>
                      <span>Work Item: </span>
                      <Select
                        size="sm"
                        style={{ width: '500px' }}
                        isDisabled={!selectedPhase[index] || !selectedPhase[index][altIndex]}
                        options={work_item_options}
                        value={selectedWorkItem[index] && selectedWorkItem.length > index ? selectedWorkItem[index][altIndex] : null}
                        onChange={(selectedOption) => handleWorkItemChange(selectedOption.value, index, altIndex)}
                      ></Select>
                    </div>
                  ) : (
                    <span>{`Work Item: ${alternateItem.sow}`}</span>
                  )}
                </CCol>
                <CCol>{renderDateTable(alternateItem.old_start, alternateItem.new_start, alternateItem.old_end, alternateItem.new_end, 1, index, altIndex)}</CCol>

                {/* Render dependencies in alternate section */}
                {alternateItem.dependencies && renderDependencies(alternateItem.dependencies, alternateItem.dependencies.length > 1 ? 'Dependent Tasks' : 'Dependent Task')}
              </React.Fragment>
            ))}
          </CCol>
        </CRow>
      </div>
    );
  };

  // const recommendationOnDiversion = recommendationsDataDiversion?.map(renderRecommendationItem);
  const recommendationOnDiversion = recommendationsDataDiversion.length > 0 ? renderRecommendationItem(recommendationsDataDiversion) : null;

  const recommendationOnDiversionModal = recommendationsDataDiversion.map(renderRecommendationItemModal);
  // const recommendationOnDiversionModal = recommendationsDataDiversion.length > 0 ? renderRecommendationItemModal(recommendationsDataDiversion[modalItem]) : '';

  const RainfallAlert = () => {
    let icon = null;
    let rain = '';
    const timeStamp = rainTimeStamp;
    const date = new Date(timeStamp);

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const formattedTime = date.toLocaleTimeString('en-US');

    const formattedDateTime = `${formattedDate} at ${formattedTime}`;

    if (rainIntensity === 1) {
      icon = <BsFillCloudDrizzleFill size={30} />;
      rain = 'light rain';
    } else if (rainIntensity === 2) {
      icon = <BsCloudRainFill size={30} />;
      rain = 'moderate rain';
    } else {
      icon = <BsCloudLightningRainFill size={30} />;
      rain = 'heavy rain';
    }
    if (!isCatchUpRecoAllowed) {
      return null;
    }

    if (rainIntensity === 0) {
      return null;
    }

    return (
      <>
        <CListGroupItem onClick={() => setExpandAlterWeather(!expandAlterWeather)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <div>
                <strong>Rainfall Alert !</strong>{' '}
              </div>
              <div style={{ margin: '10px 0px' }}>
                <span>
                  We've noticed unexpected <strong>{rain}</strong> last <strong>{formattedDateTime}</strong> that may affect your project timeline. You may want to run a catch-up plan to stay on track.
                </span>
              </div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3" style={{ margin: '10px 0px' }}>
              {/* <img className="ai-icon" src={ai2} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} /> */}
              {icon}
            </CCol>
          </CRow>
        </CListGroupItem>
      </>
    );
  };

  const HeatIndexNotif = () => {
    let color = null;

    console.log('heatIndex', heatIndex);

    if (heatIndex >= 27 && heatIndex <= 32) {
      color = '#FDE047'; //yellow
    } else if (heatIndex > 32 && heatIndex <= 41) {
      color = '#FFA500'; //dark yellow
    } else if (heatIndex > 41 && heatIndex <= 51) {
      color = '#EA580C'; //orange
    } else if (heatIndex > 51) {
      color = '#B42A19'; //red
    } else {
      color = null;
    }

    if (!isCatchUpRecoAllowed) {
      return null;
    }

    if (heatIndex < 27) {
      return null;
    }

    return (
      <>
        <CListGroupItem>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <div>
                <strong>Elevated Heat Index Alert!</strong>{' '}
              </div>
              <div style={{ margin: '10px 0px' }}>
                <span>
                  Heat index is at <strong style={{ color: color }}>{`${heatIndex}° C`}</strong> which might hinder safe working conditions and project progress. You might want to adjust work schedules or review safety protocols.
                </span>
              </div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3" style={{ margin: '10px 0px' }}>
              {/* <img className="ai-icon" src={ai2} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} /> */}
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaSun
                  size={36}
                  style={{
                    color: color,
                  }}
                />
                <FaTemperatureHigh
                  size={20}
                  style={{
                    color: color,
                    position: 'absolute',
                    top: -5,
                    right: -16,
                    zIndex: 1,
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CListGroupItem>
      </>
    );
  };

  const UndoWeatherRecommendation = () => {
    const Swal = require('sweetalert2');
    const [flag, setFlag] = useState(0);

    useEffect(() => {
      const getFlag = () => {
        $axios.post('/recommender/undo/flag', { project_id: props.project_id }).then((response) => {
          setFlag(response.data.flag);
        });
      };
      getFlag();
    }, []);

    const undoDiversion = () => {
      Swal.fire({
        icon: 'question',
        title: 'Are you sure?',
        text: 'Reverting will roll back any changes made based on the weather recommendation, although all progress achieved will be preserved.',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          $axios
            .post('recommender/undo', { project_id: props.project_id })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    if (!isCatchUpRecoAllowed) {
      return null;
    }

    if (flag === 0) {
      return null;
    }

    return (
      <>
        <CListGroupItem onClick={() => setExpandUndoRecommendation(!expandUndoRecommendation)}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <div>
                <strong>Revert Diversion Plan?</strong>{' '}
              </div>
              <div style={{ margin: '10px 0px' }}>
                <span>It looks like it did not rain today. Do you want to revert the previously accepted diversion plan changes? </span>
              </div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>

        {expandUndoRecommendation && (
          <CListGroupItem>
            <CRow xm="12" sm="12" md="12" lg="12">
              <CCol xm="12" sm="12" md="12" lg="12">
                <div style={{ margin: '0px 0px 10px 0px' }}>
                  <span>Reverting will roll back any changes made based on the weather recommendation, although all progress achieved will be preserved. </span>
                </div>
                {/* <div style={{ padding: '1rem' }}>
                  <Select options={options} onChange={setSelectedOption} value={selectedOption}></Select>
                </div> */}
              </CCol>
            </CRow>
            <CRow style={{ justifyContent: 'flex-end' }}>
              <CButton type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }} onClick={undoDiversion}>
                Revert
              </CButton>
              <CButton type="submit" style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D', marginRight: '10px' }}>
                Cancel
              </CButton>
            </CRow>
          </CListGroupItem>
        )}
      </>
    );
  };

  const LowStockNotif = (handleRedirectOnLowStock, project_id) => {
    const [data, setData] = useState([]);
    let color = null;
    // const data = [
    //   { material_id: 1, material_name: 'PPR Pipe #3/4 (1002 (27) Plumbing Works)', quantity: 10 },
    //   { material_id: 2, material_name: 'Tie Wire #16 (500 (3) b1 Lined Canal Rectangular Concrete Linear Meter)', quantity: 20 },
    // ];
    useEffect(() => {
      const fetchNotifications = async () => {
        await $axios.get('material/utilization/get-notification-list', { params: { project: project_id } }).then((response) => {
          setData(response.data.response);
        });
      };
      fetchNotifications();
    }, []);

    return (
      <>
        {data.map((item) => (
          <CListGroupItem key={item.material_id}>
            <CRow>
              <CCol xm="12" sm="12" md="9" lg="9">
                <div>
                  <strong>Low Stock Alert!</strong>{' '}
                </div>
                <div style={{ margin: '10px 0px' }}>
                  <span style={{ textAlign: 'justify' }}>
                    The stock for <strong>{item.material_name}</strong> has reached the defined reorder point. Would you like to{' '}
                    <button onClick={() => handleRedirectOnLowStock(item.material_name)} style={{ color: '#3B74BE', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer' }}>
                      request
                    </button>{' '}
                    additional stock?
                  </span>
                </div>
              </CCol>
              <CCol xm="12" sm="12" md="3" lg="3" style={{ margin: '10px 0px' }}>
                {/* <img className="ai-icon" src={ai2} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} /> */}
                <div style={{ position: 'relative', display: 'inline-block', marginTop: '0.8rem' }}>
                  <FaBoxOpen
                    size={36}
                    style={{
                      color: color,
                    }}
                  />
                  <FaExclamationTriangle
                    size={20}
                    style={{
                      color: '#B42A19',
                      position: 'absolute',
                      top: -15,
                      right: 8,
                      zIndex: 1,
                    }}
                  />
                </div>
              </CCol>
            </CRow>
          </CListGroupItem>
        ))}
      </>
    );
  };

  const SlippageAlert = () => {
    const [isExpanded, setExpanded] = useState(false);
    const [slippage, setSlippage] = useState(null);
    useEffect(() => {
      const fetchSlippage = () => {
        $axios
          .post('project/slippage/trigger', { project_id: props.project_id })
          .then((response) => {
            console.log('slippage response', response.data.data_list.slippage);
            setSlippage(response.data.data_list?.slippage?.toFixed(2));
          })
          .catch((error) => {
            console.log('error slippage', error);
          });
      };
      fetchSlippage();
    }, [props.project_id]);
    const handleToggleRecommendations = (index) => {
      setExpanded(!isExpanded);
    };
    console.log('slippage value', slippage);
    if (slippage == null) {
      return null;
    }

    return (
      <div>
        <CListGroupItem onClick={handleToggleRecommendations} style={{ cursor: 'pointer' }}>
          <CRow>
            <CCol xm="12" sm="12" md="9" lg="9">
              <div style={{ marginBottom: '10px' }}>
                <strong>Slippage Alert!</strong>
              </div>
              <span>
                We've noticed that this project has an accumulated slippage of <strong>{slippage}%.</strong> {slippage <= -5 ? 'Would you like to create a catch-up plan?' : ''}
              </span>
              <div className="list-font-style"></div>
            </CCol>
            <CCol xm="12" sm="12" md="3" lg="3">
              <img className="ai-icon" src={ai} alt="recommender icon" style={{ marginRight: '8px', height: '36px', width: '36px', cursor: 'pointer' }} />
            </CCol>
          </CRow>
        </CListGroupItem>
        {isExpanded && slippage <= -5 && (
          <CListGroupItem>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CButton type="submit" onClick={() => goToScheduleEditableCatchup(slippage)} style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
                Create
              </CButton>
              <CButton type="submit" onClick={() => handleDeclineCatchup(5)} style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
                Dismiss
              </CButton>
            </div>
          </CListGroupItem>
        )}
      </div>
    );
  };

  // return (
  //   <>
  //     <CCol xm="12" sm="12" md="12" lg="12">
  //       <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
  //         <CListGroupItem color="danger">
  //           <span style={{ color: 'white', fontWeight: '600' }}>NEXT BEST ACTIVITY</span>
  //         </CListGroupItem>
  //         {notifications}
  //         {issue !== null && issue.limited && (
  //           <CListGroupItem style={{ textAlign: 'center' }}>
  //             <CButton color="link" onClick={ShowIssue}>
  //               {showMore == 1 ? (
  //                 <div>
  //                   <span>Show more</span>
  //                   <div>
  //                     <FaAngleDown style={{ width: '20px', height: '20px' }} />
  //                   </div>
  //                 </div>
  //               ) : (
  //                 <div>
  //                   <span>Show less</span>
  //                   <div>
  //                     <FaAngleUp style={{ width: '20px', height: '20px' }} />
  //                   </div>
  //                 </div>
  //               )}
  //             </CButton>
  //           </CListGroupItem>
  //         )}
  //       </CListGroup>
  //     </CCol>

  //     {/* <CModal show={modal} onClose={() => setModal(!modal)} size="lg" style={{ borderRadius: '5px', padding: '0px' }} centered>
  //       <CModalHeader closeButton style={{ borderColor: 'white', paddingBottom: '0px' }}>
  //         <CModalTitle style={{ fontSize: '28px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', marginBottom: '0px' }}>Diversion Plan</CModalTitle>
  //       </CModalHeader>
  //       <CModalBody style={{ padding: '0' }}>{recommendationOnDiversionModal}</CModalBody>
  //       <CModalFooter style={{ borderColor: 'white' }}>
  //         <CRow style={{ width: '100%', justifyContent: 'flex-end' }}>
  //           <CButton onClick={handleAcceptDiversion} type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
  //             Accept
  //           </CButton>
  //           <CButton type="submit" style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
  //             Dismiss
  //           </CButton>
  //         </CRow>
  //       </CModalFooter>
  //     </CModal> */}

  //     {/* <CCol xm="12" sm="12" md="12" lg="12">
  //       <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
  //         <CListGroupItem style={{ border: 'none', display: 'flex', alignItems: 'flex-start', paddingLeft: '0px' }} color="transparent">
  //           <img src={recommender} alt="Recommender Logo" style={{ marginRight: '8px', height: '24px', width: '24px' }} />
  //           <span style={{ color: '#23303D', fontWeight: '600', fontSize: '18px' }}>Recommendations</span>
  //         </CListGroupItem>
  //         <div style={{ marginTop: '5px' }}></div>
  //         {recommendations}
  //       </CListGroup>
  //     </CCol> */}
  //   </>
  // );

  {
    /** HIDDEN - Recommended next action */
  }
  // return (
  //   <>
  //     <CCol xm="12" sm="12" md="12" lg="12">
  //       <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
  //         <CListGroupItem style={{ border: 'none', display: 'flex', alignItems: 'flex-start', paddingLeft: '0px' }}>
  //           <img src={nba} alt="NBA Logo" style={{ marginRight: '8px', height: '18px', width: '20px' }} />
  //           <span style={{ color: '#23303D', fontWeight: '600', fontSize: '18px', whiteSpace: 'nowrap' }}>Recommended next action</span>
  //         </CListGroupItem>
  //         <div style={{ marginTop: '5px' }}></div>
  //         {/* {rdx_module !== 'project-scope-of-work' && recommendationOnDependency} */}
  //         {/* {rdx_module === 'project-scope-of-work' && recommendations} */}
  //         {/* {rdx_module === 'project-scope-of-work' && recommendationOnDiversion} */}
  //         {notifications}
  //         {issue !== null && issue.limited && (
  //           <CListGroupItem style={{ textAlign: 'center' }}>
  //             <CButton color="link" onClick={ShowIssue}>
  //               {showMore == 1 ? (
  //                 <div>
  //                   <span>Show more</span>
  //                   <div>
  //                     <FaAngleDown style={{ width: '20px', height: '20px' }} />
  //                   </div>
  //                 </div>
  //               ) : (
  //                 <div>
  //                   <span>Show less</span>
  //                   <div>
  //                     <FaAngleUp style={{ width: '20px', height: '20px' }} />
  //                   </div>
  //                 </div>
  //               )}
  //             </CButton>
  //           </CListGroupItem>
  //         )}
  //       </CListGroup>
  //     </CCol>

  //     {/* <CCol xm="12" sm="12" md="12" lg="12">
  //       <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
  //         <CListGroupItem style={{ border: 'none', display: 'flex', alignItems: 'flex-start', paddingLeft: '0px' }} color="transparent">
  //           <img src={recommender} alt="Recommender Logo" style={{ marginRight: '8px', height: '24px', width: '24px' }} />
  //           <span style={{ color: '#23303D', fontWeight: '600', fontSize: '18px' }}>Recommendations</span>
  //         </CListGroupItem>
  //         <div style={{ marginTop: '5px' }}></div>
  //         {recommendations}
  //       </CListGroup>
  //     </CCol> */}
  //   </>
  // );

  {
    /** HIDDEN - Recommended next action */
  }

  const scopeStatus = workItemStatus?.map((item, index) => {
    const dayText = item.days === 1 ? "day" : "days";
    const message =
      item?.type === "overdue"
        ? `${item?.scope_of_work} - ${item?.work_item} is overdue by ${item?.days} ${dayText}.`
        : item.type === "to-start"
        ? `${item?.scope_of_work} - ${item?.work_item} is scheduled to start in ${item?.days} ${dayText}.`
        : item.type === "to-end"
        ? `${item?.scope_of_work} -${item?.work_item} is scheduled to end in ${item?.days} ${dayText}.`
        : "No status available.";
  
    return (
      <CListGroupItem key={index}>
        <CRow>
          <CCol xm="12" sm="12" md="9" lg="9">
            <span>{message}</span>
            <div className="list-font-style"></div>
          </CCol>
          <CCol xm="12" sm="12" md="3" lg="3">
            <FaExclamationTriangle
              style={{ width: "30px", height: "30px", marginTop: "10px" }}
              className={item?.type === "overdue" ? "text-danger" : "text-primary"}
            />
          </CCol>
        </CRow>
      </CListGroupItem>
    );
  });
  
  const MaterialRecommendationData = () => {
    const [expandedItems, setExpandedItems] = useState({});

    const handleToggleMaterialRecommendations = (index) => {
      setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };
    const handleReviewMaterial = (item) => {
      history.push(`/projects/Index?id=${props?.project_id}&menu=4&search=${item?.work_item}`);
    };
    return (
      <>
        {materialRequestData
          ? materialRequestData.map((item, index) => (
              <div key={index}>
                <CListGroupItem onClick={() => handleToggleMaterialRecommendations(index)} style={{ cursor: 'pointer' }}>
                  <CRow>
                    <CCol xm="12" sm="12" md="9" lg="9">
                      <div style={{ marginBottom: '10px' }}>
                        <strong>Material Recommendation</strong>
                      </div>
                      <span>{`${item.work_item} will ${item.remaining_days === 1 ? 'begin today' : `begin in ${item.remaining_days} days`}. Please request the necessary materials. Review?`}</span>
                    </CCol>
                    <CCol xm="12" sm="12" md="3" lg="3">
                      <img
                        className="ai-icon"
                        src={ai}
                        alt="recommender icon"
                        style={{
                          marginRight: '8px',
                          height: '36px',
                          width: '36px',
                          cursor: 'pointer',
                        }}
                      />
                    </CCol>
                  </CRow>
                </CListGroupItem>
                {expandedItems[index] && (
                  <CListGroupItem>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <CButton
                        type="submit"
                        onClick={() => handleReviewMaterial(item)}
                        style={{
                          backgroundColor: '#23303D',
                          color: '#FFFFFF',
                          marginRight: '10px',
                        }}
                      >
                        Review
                      </CButton>
                    </div>
                  </CListGroupItem>
                )}
              </div>
            ))
          : null}
      </>
    );
  };

  console.log('rdx_module: ',rdx_module);
  return (
    <>
      <CCol xm="12" sm="12" md="12" lg="12">
        <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
          <CListGroupItem style={{ border: 'none', display: 'flex', alignItems: 'flex-start', paddingLeft: '0px' }}>
            <img src={nba} alt="NBA Logo" style={{ marginRight: '8px', height: '18px', width: '20px' }} />
            <span style={{ color: '#23303D', fontWeight: '600', fontSize: '18px', whiteSpace: 'nowrap' }}>Recommended next action</span>
          </CListGroupItem>
          <div style={{ marginTop: '5px' }}></div>
          {rdx_module === 'project-materials' && LowStockNotif(props.handleRedirectOnLowStock, props.project_id)}
          {rdx_module === 'project-scope-of-work' && UndoWeatherRecommendation()}
          {rdx_module === 'project-scope-of-work' && HeatIndexNotif()}
          {rdx_module === 'project-materials' && <MaterialRecommendationData />}
          {rdx_module === 'project-scope-of-work' && RainfallAlert()}
          {(rdx_module === 'project-scope-of-work' || rdx_module === 'schedule') && SlippageAlert()}
          {rdx_module === 'project-scope-of-work' && isCatchUpRecoAllowed && props.showRecommenderDependency === true && recommendationOnDependency}
          {rdx_module === 'project-scope-of-work' && isCatchUpRecoAllowed && catchupPlanAvailable && recommendations}
          {rdx_module === 'project-scope-of-work' && isCatchUpRecoAllowed && catchupPlanAvailableWeather && recommendationsForWeather}
          {rdx_module === 'project-scope-of-work' && isCatchUpRecoAllowed && recommendationOnDiversion}
          {/* {rdx_module !== 'project-scope-of-work' && isCatchUpRecoAllowed && catchupPlanAvailable && recommendationsCatchUp}
          {rdx_module !== 'project-scope-of-work' && isCatchUpRecoAllowed && catchupPlanAvailableWeather && recommendationsCatchUpWeather} */}
          {rdx_module === 'schedule' && isCatchUpRecoAllowed && catchupPlanAvailable && recommendationsCatchUp}
          {rdx_module === 'schedule' && isCatchUpRecoAllowed && catchupPlanAvailableWeather && recommendationsCatchUpWeather}
          {notifications}
          {rdx_module === 'project-scope-of-work' && scopeStatus}
          {issue !== null && issue.limited && (
            <CListGroupItem style={{ textAlign: 'center' }}>
              <CButton color="link" onClick={ShowIssue}>
                {showMore == 1 ? (
                  <div>
                    <span>Show more</span>
                    <div>
                      <FaAngleDown style={{ width: '20px', height: '20px' }} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <span>Show less</span>
                    <div>
                      <FaAngleUp style={{ width: '20px', height: '20px' }} />
                    </div>
                  </div>
                )}
              </CButton>
            </CListGroupItem>
          )}
        </CListGroup>
      </CCol>

      <CModal show={modal} onClose={() => setModal(!modal)} size="xl" style={{ borderRadius: '5px', padding: '0px' }} centered>
        <CModalHeader closeButton style={{ borderColor: 'white', paddingBottom: '0px' }}>
          <CModalTitle style={{ fontSize: '28px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', marginBottom: '0px' }}>Diversion Plan</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ padding: '0', maxHeight: '700px', overflow: 'auto' }}>{recommendationOnDiversionModal}</CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CRow style={{ width: '100%', justifyContent: 'flex-end' }}>
            <CButton onClick={handleAcceptDiversion} type="submit" style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px' }}>
              Accept
            </CButton>
            <CButton onClick={handleDeclineDiversion} type="submit" style={{ backgroundColor: '#FFFFFF', color: '#23303D', border: '1px solid #23303D' }}>
              Dismiss
            </CButton>
          </CRow>
        </CModalFooter>
      </CModal>

      {/* <CCol xm="12" sm="12" md="12" lg="12">
        <CListGroup style={{ fontSize: '12px', marginTop: '20px' }}>
          <CListGroupItem style={{ border: 'none', display: 'flex', alignItems: 'flex-start', paddingLeft: '0px' }} color="transparent">
            <img src={recommender} alt="Recommender Logo" style={{ marginRight: '8px', height: '24px', width: '24px' }} />
            <span style={{ color: '#23303D', fontWeight: '600', fontSize: '18px' }}>Recommendations</span>
          </CListGroupItem>
          <div style={{ marginTop: '5px' }}></div>
          {recommendations}
        </CListGroup>
      </CCol> */}
    </>
  );
  {
    /** HIDDEN - Recommended next action */
  }
};

export default ThingsToConsider;
